// Hashes for HashNavigation

export const HASHES = {
  LOGIN: '#login',
  LOGIN_FAILED: '#login-failed',
  LOGIN_CANCELLED: '#login-cancelled',
  CERTFICATE: '#nipt-certificate',
  LESSON_DETAIL: '#lesson-detail',
  EDIT_PHONE: '#edit-phone',
  EDIT_PROFILE: '#edit-profile',
};

export const PHONE_VALIDATION_LENGTH = 4;

export const COUNTRY_CODES: { [char: string]: string } = {
  SWEDEN: '+46',
  NORWAY: '+47',
  DENMARK: '+45',
};

export const COUNTRY_EMOJIS: { [char: string]: string } = {
  SWEDEN: '🇸🇪',
  NORWAY: '🇳🇴',
  DENMARK: '🇩🇰',
};

export enum Market {
  NO = 'NO', // eddalabs.no /  booking-eddalabs.5dev.se
  SE = 'SE', // eddalabs.com / eddalabs.5dev.se
  PARTNER = 'PARTNER', // niptskolen.no / niptschool.5dev.se
  ENDOMETRIOSIS = 'ENDOMETRIOSIS', // endometrioseguiden.no / endoguiden.5dev.se

  OUS = 'OUS', // ous.fostermedisin.no / eddalabs-ous.5dev.se
  OUS_VIDEO = 'OUS_VIDEO', // video.fostermedisin.no / eddalabs-ous-video.5dev.se
  OUS_NIPT = 'OUS_NIPT', // nipt.fostermedisin.no / eddalabs-ous-nipt.5dev.se
  OUS_ULTRASOUND = 'OUS_ULTRASOUND', // ultrasound,fostermedisin.no / eddalabs-ous-ultrasound.5dev.se
}

export const MARKET_SCHOOLS = ['PARTNER', 'ENDOMETRIOSIS'];

// List of markets that should have a fullscreen login view
export const FULLSCREEN_LOGIN_MARKETS = [
  'ENDOMETRIOSIS',
  'OUS',
  'OUS_VIDEO',
  'OUS_NIPT',
  'OUS_ULTRASOUND',
];

export const OUS_MARKETS = ['OUS', 'OUS_VIDEO', 'OUS_NIPT', 'OUS_ULTRASOUND'];

export const DEFAULT_MARKET = Market.NO;

export const SESSION_COOKIE_NAME = 'sessionid';
export const CSRF_COOKIE_NAME = 'csrftoken';
export const COOKIE_CONSENT_COOKIE_NAME = 'cookies_ok';
export const COOKIE_CONSENT_MARKETING_COOKIE_NAME = 'cookies_marketing_ok';

export type hashesType = typeof HASHES;

export const BOOKING_STATUS = {
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
};

export const MODAL_ROOT_ID = 'modal-root';
