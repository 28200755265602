import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { MODAL_ROOT_ID } from 'utils/constants';

import SVGClose from '../../icons/close.svg';
import Backdrop from '../Backdrop';
import Button from '../Button';
import Card from '../Card';
import Container from '../Container';
import ScreenReaderOnly from '../ScreenReaderOnly';
import Swoosh from '../Swoosh';
import styles from './Modal.module.css';

export type ModalProps = {
  status?: 'neutral' | 'success' | 'error' | 'warning' | 'info';
  size?: 'normal' | 'slim' | 'full' | 'admin' | 'nipt';
  open: boolean;
  description?: ReactElement | ReactElement[] | null;
  noPortal?: boolean;
  containerClass?: string;
  actions?: ReactElement | ReactElement[];
  onClose?: () => void;
  cardRef?: React.Ref<HTMLDivElement>;
  title?: string | ReactElement;
  className?: string;
};

const Modal: React.FC<ModalProps> = ({
  children,
  noPortal = false,
  actions,
  open,
  onClose,
  cardRef,
  className,
  status = 'neutral',
  size = 'normal',
  containerClass = '',
  description,
  title = undefined,
}) => {
  const ROOT = typeof window !== 'undefined' ? document.getElementById(MODAL_ROOT_ID) : null;

  const rendered = (
    <Backdrop open onClick={onClose}>
      {/* <Container fullHeight className={styles.wrapper}> */}
      <Card
        innerRef={cardRef}
        classNameInner={styles.inner}
        className={cx(
          styles.root,
          {
            [styles.slim]: size === 'slim',
            [styles.full]: size === 'full',
            [styles.admin]: size === 'admin',
            [styles.nipt]: size === 'nipt',
          },
          className,
        )}
        padded={false}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div
          className={cx(styles.top, {
            [styles.error]: status === 'error',
            [styles.success]: status === 'success',
            [styles.warning]: status === 'warning',
            [styles.info]: status === 'info',
          })}
        >
          <span className={styles.title}>{title}</span>
          {onClose && (
            <Button
              variant="text"
              className={styles.button}
              color="white"
              icon={SVGClose}
              onClick={onClose}
            >
              <ScreenReaderOnly>close modal</ScreenReaderOnly>
            </Button>
          )}
        </div>
        {status === 'success' && <Swoosh from="green" to="white" />}
        {status === 'error' && <Swoosh from="error" to="white" />}
        {status === 'warning' && <Swoosh from="warning" to="white" />}
        {description}
        <Container
          className={cx(styles.container, {
            [styles.contentContainerSlim]: size === 'slim',
            [styles.containerPaddingBottom]: actions,
            containerClass,
          })}
        >
          {children}
        </Container>
        {actions}
      </Card>
      {/* </Container> */}
    </Backdrop>
  );

  if (noPortal) {
    return open ? rendered : null;
  }
  if (open && ROOT) {
    return ReactDOM.createPortal(rendered, ROOT);
  }
  return null;
};

export default Modal;
