import React from 'react';
import cx from 'classnames';
import NextLink, { LinkProps } from 'next/link';

import WithColor from '../../hocs/WithColor';
import Clickable from '../Clickable';
import styles from './Link.module.css';

export type Props = {
  className?: string;
  disabled?: boolean;
  icon?: React.FC<React.SVGAttributes<React.ReactNode>>;
  iconPlacement?: 'before' | 'after';
  block?: boolean;
  big?: boolean;
  small?: boolean;
  external?: boolean;
  target?: string;
} & React.ComponentPropsWithoutRef<'button' | 'a'>;

/**
 * A wrapper for the next/link-component that passes most of the props along to said.
 * @disabled Disables the link. Both <a> and <button>-type disables are supported.
 * @icon SVG icon
 * @iconPlacement Place the icon before or after the text
 * @block make block-level
 * @small Make the text slightly smaller
 * @big Make the hitbox slightly bigger, and the button easier to press. works poorly inline.
 */

const Link: React.FC<Props & LinkProps> = ({
  children,
  big = false,
  block = false,
  disabled = false,
  small = false,
  external = false,
  target = '',
  iconPlacement = 'after',
  icon: Icon,
  className,
  ...rest
}) => {
  const clickable = (
    <Clickable
      className={cx(
        styles.root,
        { [styles.block]: block, [styles.big]: big, [styles.small]: small },
        className,
      )}
      disabled={disabled}
    >
      {Icon && iconPlacement === 'before' && (
        <span className={styles.iconWrapper}>
          <Icon className={styles.icon} fill="currentColor" />
        </span>
      )}
      {children}
      {Icon && iconPlacement === 'after' && (
        <span className={cx(styles.iconWrapper, styles.iconAfter)}>
          <Icon className={styles.icon} fill="currentColor" />
        </span>
      )}
    </Clickable>
  );
  if (external) {
    return (
      <a
        className={cx(
          styles.root,
          { [styles.block]: block, [styles.big]: big, [styles.small]: small },
          className,
        )}
        target={target}
        href={rest.href as string}
      >
        {clickable}
      </a>
    );
  } else {
    return (
      <NextLink passHref {...(rest as LinkProps)}>
        {clickable}
      </NextLink>
    );
  }
};

export default WithColor(Link);
