import React from 'react';
import Backdrop from 'components/Backdrop';

import Typography from '../../components/Typography';
import AppContext from '../../containers/AppContext';
import SpinnerSVG from '../../icons/spinner.svg';
import styles from './Loading.module.css';

const Loading: React.FC = ({ children }) => {
  const { loading } = React.useContext(AppContext);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loading === true && active === false) {
        window.requestAnimationFrame(() => {
          setActive(true);
        });
      } else if (active === true && loading === false) {
        window.requestAnimationFrame(() => {
          setActive(false);
        });
      }
    }
  }, [active, loading]);

  return loading ? (
    <Backdrop>
      <div className={styles.root}>
        <div className={styles.spinner}>
          <SpinnerSVG fill="currentColor" stroke="none" />
        </div>
        {children && <Typography>{children}</Typography>}
      </div>
    </Backdrop>
  ) : null;
};

export default Loading;
