import { Market } from '../constants';

// Statically define toggleable features

export enum ToggleableFeature {
  // Code
  DASHBOARD_EXTENDED_TIMELINE,
  // Components
  DASHBOARD_COMPLETED,
  CLINIC_ACTION,
  LANGUAGE_PICKER,
  PHONE_ACTION,
  FOOTER,
  HAMBURGER_MENU,
  DASHBOARD_TIMELINE,
  // Pages
  HOME_PAGE,
  ABOUT_PAGE,
  BOOKING_PAGE,
  NIPT_SCHEMA_PAGE,
  ORDER_PAGE,
  CLINICS_PAGE,
  CLINIC_BOOK_PAGE,
  CLINIC_ADMIN,
  CLINIC_ADMIN_PATIENTS,
  AVAILABLE_TIME_SLOTS_PAGE,
  // Languages
  ENGLISH_LANGUAGE,
  SWEDISH_LANGUAGE,
  NORWEGIAN_LANGUAGE,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ALL = Object.entries(ToggleableFeature).map((key, value) => value);

export const MarketConfig: { [key in Market]: Array<ToggleableFeature> } = {
  [Market.SE]: [
    ToggleableFeature.DASHBOARD_EXTENDED_TIMELINE,
    ToggleableFeature.DASHBOARD_COMPLETED,
    ToggleableFeature.CLINIC_ACTION,
    ToggleableFeature.PHONE_ACTION,
    ToggleableFeature.BOOKING_PAGE,
    ToggleableFeature.NIPT_SCHEMA_PAGE,
    ToggleableFeature.ORDER_PAGE,
    ToggleableFeature.CLINICS_PAGE,
    ToggleableFeature.HOME_PAGE,
    ToggleableFeature.ABOUT_PAGE,
    ToggleableFeature.FOOTER,
    ToggleableFeature.HAMBURGER_MENU,
    ToggleableFeature.DASHBOARD_TIMELINE,
  ],
  [Market.NO]: [
    ToggleableFeature.DASHBOARD_COMPLETED,
    ToggleableFeature.CLINIC_ACTION,
    ToggleableFeature.CLINICS_PAGE,
    ToggleableFeature.CLINIC_BOOK_PAGE,
    ToggleableFeature.CLINIC_ADMIN,
    ToggleableFeature.AVAILABLE_TIME_SLOTS_PAGE,
    ToggleableFeature.HOME_PAGE,
    ToggleableFeature.ABOUT_PAGE,
    ToggleableFeature.FOOTER,
    ToggleableFeature.HAMBURGER_MENU,
    ToggleableFeature.DASHBOARD_TIMELINE,
  ],
  [Market.PARTNER]: [
    ToggleableFeature.LANGUAGE_PICKER,
    ToggleableFeature.HOME_PAGE,
    ToggleableFeature.ABOUT_PAGE,
    ToggleableFeature.ENGLISH_LANGUAGE,
    ToggleableFeature.NORWEGIAN_LANGUAGE,
    ToggleableFeature.FOOTER,
    ToggleableFeature.HAMBURGER_MENU,
    ToggleableFeature.DASHBOARD_TIMELINE,
  ],
  [Market.ENDOMETRIOSIS]: [
    ToggleableFeature.LANGUAGE_PICKER,
    ToggleableFeature.ENGLISH_LANGUAGE,
    ToggleableFeature.SWEDISH_LANGUAGE,
    ToggleableFeature.NORWEGIAN_LANGUAGE,
    ToggleableFeature.FOOTER,
    ToggleableFeature.HAMBURGER_MENU,
    ToggleableFeature.DASHBOARD_TIMELINE,
  ],
  [Market.OUS]: [
    ToggleableFeature.LANGUAGE_PICKER,
    ToggleableFeature.ENGLISH_LANGUAGE,
    ToggleableFeature.SWEDISH_LANGUAGE,
    ToggleableFeature.NORWEGIAN_LANGUAGE,
  ],
  [Market.OUS_NIPT]: [
    ToggleableFeature.LANGUAGE_PICKER,
    ToggleableFeature.ENGLISH_LANGUAGE,
    ToggleableFeature.SWEDISH_LANGUAGE,
    ToggleableFeature.NORWEGIAN_LANGUAGE,
    ToggleableFeature.HAMBURGER_MENU,
  ],
  [Market.OUS_ULTRASOUND]: [
    ToggleableFeature.LANGUAGE_PICKER,
    ToggleableFeature.ENGLISH_LANGUAGE,
    ToggleableFeature.SWEDISH_LANGUAGE,
    ToggleableFeature.NORWEGIAN_LANGUAGE,
    ToggleableFeature.HAMBURGER_MENU,
  ],
  [Market.OUS_VIDEO]: [
    ToggleableFeature.LANGUAGE_PICKER,
    ToggleableFeature.ENGLISH_LANGUAGE,
    ToggleableFeature.SWEDISH_LANGUAGE,
    ToggleableFeature.NORWEGIAN_LANGUAGE,
  ],
};
