import React, { ReactElement } from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import styles from './Radio.module.css';

interface Props extends FieldRenderProps<string | number, HTMLInputElement> {
  className?: string;
  block?: boolean;
  labelPlacement?: 'before' | 'after';
  labelClass?: string;
  label?: string | ReactElement;
}

/**
 * RadioButton for use with FinalForm.
 * if FinalForm is not used, props for the RadioButton has to be passed through the "input" prop.
 *
 * Example usage:
 * <Field name={""} type="radio" render={Radio}/>
 *
 *
 * @param className "composed className"
 * @param label label
 * @param disable disable the radio
 * @param labelPlacement Render the label before/after the radio-button
 */

const Radio: React.FC<Props> = ({
  className,
  labelClass,
  labelPlacement = 'after',
  input,
  label,
  meta,
  ...props
}) => {
  const renderedLabel = (
    <span
      className={cx(labelClass, {
        [styles.labelBefore]: labelPlacement === 'before',
        [styles.labelAfter]: labelPlacement === 'after',
      })}
    >
      {label} {meta.error && meta.error}
    </span>
  );
  return (
    <label className={cx(styles.root, className)}>
      <input type="radio" className={styles.input} {...input} {...props} />
      {Boolean(label && labelPlacement === 'before') && renderedLabel}
      <span aria-hidden="true" className={styles.fauxRadio} />
      {Boolean(label && labelPlacement === 'after') && renderedLabel}
    </label>
  );
};

export default Radio;
