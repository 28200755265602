import React from 'react';
import ReactDOM from 'react-dom';
import { Field, Form, FormRenderProps } from 'react-final-form';
import Block from 'components/Block';
import Button from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import Typography from 'components/Typography';
import * as NODES from 'djedi-nodes/cookie-bar';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import Script from 'next/script';
import {
  COOKIE_CONSENT_COOKIE_NAME,
  COOKIE_CONSENT_MARKETING_COOKIE_NAME,
  MODAL_ROOT_ID,
} from 'utils/constants';

const { publicRuntimeConfig } = getConfig();

import styles from './CookieBar.module.css';
const isClient = typeof window !== 'undefined';

const INITIAL_FORM_VALUES = { mandatory: true, voluntary: true };
const COOKIE_BAR_ID = 'cookieBar';

const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5PP4G4Z');`;

interface FormValues {
  mandatory: boolean;
  voluntary: boolean;
}

export type CookieBarProps = {
  onSubmit: (arg0: FormValues) => void;
};

const ActualCookieBar: React.FC<CookieBarProps> = ({ onSubmit }) => {
  const acceptAll = () => onSubmit({ mandatory: true, voluntary: true });
  return (
    <Block id={COOKIE_BAR_ID} background="powderpink" className={styles.root}>
      <Typography className={styles.content} variant="md">
        {NODES.CONTENT}
      </Typography>

      <Form onSubmit={onSubmit} initialValues={INITIAL_FORM_VALUES}>
        {({ handleSubmit, values }: FormRenderProps) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.fields}>
              <Field
                className={styles.checkbox}
                label={NODES.MANDATORY_COOKIES}
                name="mandatory"
                type="checkbox"
                render={Checkbox}
                disabled
              />
              <Field
                className={styles.checkbox}
                label={NODES.VOLUNTARY_COOKIES}
                name="voluntary"
                type="checkbox"
                render={Checkbox}
              />
            </div>
            <div className={styles.actions}>
              <Button
                block
                disabled={!values.mandatory}
                size="slim"
                variant="outlined"
                type="submit"
                className={styles.button}
              >
                {NODES.ALLOW_SELECTION}
              </Button>
              <Button block size="slim" onClick={acceptAll} className={styles.button}>
                {NODES.ALLOW_ALL}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Block>
  );
};

const CookieBar: React.FC = () => {
  const initialVoluntaryOk = Cookies.get(COOKIE_CONSENT_MARKETING_COOKIE_NAME) === 'true';
  const initialOk = Cookies.get(COOKIE_CONSENT_COOKIE_NAME) === 'true';
  const [voluntaryOk, setVoluntaryOk] = React.useState(initialVoluntaryOk);
  const [ok, setOk] = React.useState(initialOk);

  if (!ok && isClient) {
    // Render in using a portal in a separate react tree.
    const root = document.getElementById(MODAL_ROOT_ID);

    const onSubmit = ({ voluntary, mandatory }: FormValues) => {
      let domain = window.location.hostname;

      if (domain.endsWith('fostermedisin.no')) {
        domain = '.fostermedisin.no';
      }

      if (mandatory) {
        Cookies.set(COOKIE_CONSENT_COOKIE_NAME, 'true', { domain: domain });
        setOk(true);
      }
      if (voluntary) {
        Cookies.set(COOKIE_CONSENT_MARKETING_COOKIE_NAME, 'true');
        setVoluntaryOk(true);
      }
    };

    if (root) {
      return ReactDOM.createPortal(<ActualCookieBar onSubmit={onSubmit} />, root);
    }
  }

  const loadTracking = voluntaryOk && publicRuntimeConfig.ENABLE_TRACKING === 'true';

  if (loadTracking) {
    // As the Google Tag Manager script being loaded depends on explicit cookie consent
    // we insert it dynamically here (and not in _document) since we always have the
    // latest state available here.
    return (
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: GTM_SCRIPT,
        }}
      />
    );
  }

  return null;
};

export default CookieBar;
