import React from 'react';
import Onboarding from 'blocks/Onboarding';
import NODES from 'djedi-nodes/generic';
import { shouldFullscreenLogin, userHasCompletedSignup } from 'utils';
import { HASHES, OUS_MARKETS } from 'utils/constants';

import { LazyNotice } from '../../components/Notice';
import Typography from '../../components/Typography';
import AppContext from '../../containers/AppContext';
import { useHashNavigation } from '../../containers/HashNavigation';

const ModalContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { hash, setActiveHash } = useHashNavigation();
  const { loginState, currentMarket } = React.useContext(AppContext);
  const [noticeSeen, setNoticeSeen] = React.useState(false);
  const fullscreenLogin = shouldFullscreenLogin(currentMarket);

  /**
   *  Notice api
   * ==================================
   *  - Håll en lista med grejer
   *  - Låt de tajma ut om de ska det.
   *  - Låt de ha en close-callback
   *  - Använd en reducer
   *

  */

  React.useEffect(() => {
    if (hash === HASHES.LOGIN_FAILED || hash === HASHES.LOGIN_CANCELLED) {
      setNoticeSeen(false);
    }
  }, [hash, setNoticeSeen]);

  // resume the OnBoarding if the user is not done
  React.useEffect(() => {
    if (
      loginState.loggedIn &&
      !userHasCompletedSignup(loginState.user) &&
      !OUS_MARKETS.includes(currentMarket)
    ) {
      setActiveHash(HASHES.LOGIN);
    }
  }, [loginState.loggedIn, loginState.user, hash, setActiveHash]);

  return (
    <>
      {children}
      <LazyNotice
        open={hash === HASHES.LOGIN_FAILED && !noticeSeen}
        onClose={() => setNoticeSeen(true)}
        color="white"
        background="error"
        actionColor="white"
        text={<Typography variant="small">{NODES.LOGIN_FAILED}</Typography>}
      />
      <LazyNotice
        open={hash === HASHES.LOGIN_CANCELLED && !noticeSeen}
        onClose={() => setNoticeSeen(true)}
        color="black"
        background="warning"
        actionColor="black"
        text={<Typography variant="small">{NODES.LOGIN_CANCELLED}</Typography>}
      />

      {!fullscreenLogin && hash === HASHES.LOGIN && <Onboarding />}
    </>
  );
};

export default ModalContainer;
