import React from 'react';
import cx from 'classnames';

import WithColor from '../../hocs/WithColor';
import SpinnerSVG from '../../icons/spinner.svg';
import Clickable from '../Clickable';
import styles from './Button.module.css';

type Props = {
  className?: string;
  disabled?: boolean;
  fullHeight?: false;
  loading?: boolean;
  icon?: React.FC<React.SVGAttributes<React.ReactNode>>;
  iconPlacement?: 'before' | 'after';
  textAlignment?: 'start' | 'center' | 'end';
  block?: boolean;
  size?: 'regular' | 'slim';
  variant?: 'contained' | 'outlined' | 'text' | 'singleicon';
  href?: string;
  nowrap?: boolean;
} & React.ComponentPropsWithoutRef<'button' | 'a'>;

const Button: React.FC<Props> = ({
  children,
  variant = 'contained',
  size = 'regular',
  block = false,
  disabled = false,
  fullHeight = false,
  loading = false,
  href = undefined,
  iconPlacement = 'after',
  textAlignment = 'center',
  icon: Icon,
  className,
  nowrap,
  ...rest
}) => {
  return (
    <Clickable
      href={href}
      className={cx(
        styles.root,
        styles[variant],
        styles[`size-${size}`],
        { [styles.block]: block, [styles.fullHeight]: fullHeight },
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      <span
        className={cx(styles.buttonText, {
          [styles.textStart]: textAlignment === 'start',
          [styles.textEnd]: textAlignment === 'end',
          [styles.nowrap]: nowrap,
        })}
      >
        {children}
      </span>
      {Icon && !loading && (
        <Icon
          className={cx(styles.icon, {
            [styles.iconBefore]: iconPlacement === 'before',
          })}
          fill="currentColor"
        />
      )}
      {loading && (
        <div
          className={cx(
            styles.icon,
            { [styles.iconBefore]: iconPlacement === 'before' },
            styles.spinner,
          )}
        >
          <SpinnerSVG fill="currentColor" stroke="none" />
        </div>
      )}
    </Clickable>
  );
};

export default WithColor(Button);
