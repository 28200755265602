import React from 'react';

import AppContext from '../../containers/AppContext';
import { isFeatureActive } from '../../utils/FeatureFlag';
import { ToggleableFeature } from '../../utils/FeatureFlag/constants';

const Feature: React.FC<{ id: ToggleableFeature }> = ({ id, children }) => {
  const { currentMarket } = React.useContext(AppContext);
  return <>{isFeatureActive(id, currentMarket) ? children : null}</>;
};

export default Feature;
