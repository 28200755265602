import React from 'react';
import { Field, Form } from 'react-final-form';
import OnboardingContext from 'blocks/Onboarding/OnboardingContext';
import Actions from 'components/Actions';
import Button from 'components/Button';
import FieldSet from 'components/Form/FieldSet';
import Input from 'components/Form/Input';
import Typography from 'components/Typography';
import { ApiContext } from 'contexts/ApiContext';
import GENERIC_NODES from 'djedi-nodes/generic';
import { ERRORS, FORM } from 'djedi-nodes/profile';

import { ContentWrapper } from '../ContentWrapper';
import styles from './FinalizeUser.module.css';

export type UserProfileForm = {
  first_name: string | React.ReactElement;
  last_name: string | React.ReactElement;
  email: string | React.ReactElement;
};

const FinalizeUser = ({ modal }) => {
  const { state, addMissingUserData } = React.useContext(OnboardingContext);
  const api = React.useContext(ApiContext);

  const validate = (values: UserProfileForm) => {
    const errors = {} as UserProfileForm;
    if (!values.first_name) {
      errors.first_name = ERRORS.REQUIRED;
    }
    if (!values.last_name) {
      errors.last_name = ERRORS.REQUIRED;
    }

    if (!values.email) {
      errors.email = ERRORS.REQUIRED;
    }
    return errors;
  };

  return (
    <Form
      validate={validate}
      onSubmit={async values => {
        try {
          const data = await api.updateProfile(values);
          addMissingUserData(data);
          return true;
        } catch (e) {
          return e;
        }
      }}
      initialValues={state.user}
    >
      {({ handleSubmit, submitting, submitFailed, submitErrors, dirty }) => (
        <form onSubmit={handleSubmit} id="finalize-user">
          <ContentWrapper
            actions={
              <Actions contained={modal}>
                <Button
                  block
                  type="submit"
                  form="finalize-user"
                  loading={submitting}
                  disabled={!dirty}
                >
                  {GENERIC_NODES.ONBOARDING_READY}
                </Button>
              </Actions>
            }
            modal={modal}
          >
            <div>
              <Typography variant="heading" align="center" component="h1">
                {GENERIC_NODES.CHANGE_USER_HEADING}
              </Typography>
              <Typography variant="md" align="center" component="p">
                {GENERIC_NODES.CHANGE_USER_CONTENT}
              </Typography>
              <FieldSet className={styles.fieldSet}>
                <Field required name="first_name" render={Input} type="text" label={FORM.NAME} />
                <Field required name="last_name" render={Input} type="text" label={FORM.SURNAME} />
                <Field required name="email" render={Input} type="email" label={FORM.EMAIL} />
              </FieldSet>

              {submitFailed && submitErrors?.['FINAL_FORM/form-error'] && (
                <div>{submitErrors['FINAL_FORM/form-error'][0]}</div>
              )}
            </div>
          </ContentWrapper>
        </form>
      )}
    </Form>
  );
};

export default FinalizeUser;
