import React from 'react';
import cx from 'classnames';

import useNextFrame from '../../hooks/useNextFrame';
import Fullscreen from '../Fullscreen';
import styles from './Backdrop.module.css';
interface BackdropInterface {
  className?: string;
  open?: boolean;
  onClick?: () => void;
}

const Backdrop: React.FC<BackdropInterface> = ({
  open = true,
  onClick,
  children,
  className,
  ...rest
}) => {
  const active = useNextFrame(open);
  return (
    <>
      {open && (
        <div
          onClick={onClick}
          aria-hidden={!open}
          className={cx(styles.root, { [styles.mounted]: active }, className)}
          {...rest}
        >
          {children}
        </div>
      )}
      {active && <Fullscreen />}
    </>
  );
};

export default Backdrop;
