import React, { ChangeEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import { PHONE_VALIDATION_LENGTH } from '../../utils/constants';
import styles from './CodeInput.module.css';

const KEY_CODES = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

interface Props extends FieldRenderProps<string, HTMLInputElement> {
  className?: string;
  label?: string;
  error?: string | React.ReactNode;
  success?: string | React.ReactNode;
}

const CodeInput: React.FC<Props> = ({ className, input, meta, error, success }) => {
  const hasError = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error || meta.invalid;
  const statusClass = cx({ [styles.error]: hasError, [styles.success]: meta.submitSucceeded });
  const [code, setCode] = React.useState<string[]>(['', '', '', '']);
  const refs = React.useRef<Array<undefined | HTMLInputElement>>([
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  React.useEffect(() => {
    input.onChange(code.join(''));
  }, [code, input]);

  // Set code at a given index in code[]

  const setCodeAt = (i: number, val: string, next = false): void => {
    const newCode = [...code];
    const v = val.substr(-1);
    newCode.splice(i, 1, v);
    setCode(newCode);

    if (next) {
      nextInput(i);
    }
  };

  // sets a ref at a given spot in the refs[]
  const setRef = React.useCallback((r: HTMLInputElement, i: number) => {
    refs.current.splice(i, 1, r);
  }, []);

  // Focus the next input
  const nextInput = (i: number) => {
    if (i > refs.current.length) {
      // no next ref
      return;
    }

    const m = refs.current[i + 1];

    if (m) {
      m.focus();
    }
  };

  // Focus the previous input
  const prevInput = (i: number) => {
    if (i - 1 < 0) {
      // no previous ref
      return;
    }

    const m = refs.current[i - 1];

    if (m) {
      m.focus();
    }
  };

  // handle other cases than pure inputs
  const handleKeyUp =
    (i: number) =>
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      const val = code[i];
      // handle backspace while focused

      if (e.keyCode === KEY_CODES.backspace) {
        if (!val) {
          e.stopPropagation();
          prevInput(i);
        } else {
          // Set the code to be empty. But don't step to the next input
          setCodeAt(i, '', false);
        }
        return;
      }
    };

  const handleChange =
    (i: number) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      // handle an actual value change
      if (value.length) {
        setCodeAt(i, value);
        nextInput(i);
      }
    };

  return (
    <div className={cx(styles.root, className)}>
      <label className={styles.label}>
        <div className={styles.fauxInputWrapper}>
          {' '
            .repeat(PHONE_VALIDATION_LENGTH)
            .split('')
            .map((_, i) => (
              <input
                ref={r => setRef(r as HTMLInputElement, i)}
                value={code[i]}
                onChange={handleChange(i)}
                onKeyUp={handleKeyUp(i)}
                pattern="[0-9]{1}"
                autoCapitalize="false"
                autoComplete="false"
                autoCorrect="false"
                type="number"
                max="9"
                min="0"
                key={i}
                className={cx(styles.input, statusClass)}
              />
            ))}
        </div>
        {/* <input {...input} autoFocus={true} className={cx(styles.input, statusClass)} /> */}
      </label>
      {hasError && error && <span className={cx(styles.messaging, styles.error)}>{error}</span>}
      {success && meta.submitSucceeded && (
        <span className={cx(styles.messaging, styles.success)}>{success}</span>
      )}
    </div>
  );
};

export default CodeInput;
