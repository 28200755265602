import React from 'react';
import cx from 'classnames';

import styles from './Card.module.css';

export interface CardProps<T = HTMLDivElement> extends React.HTMLAttributes<T> {
  disabled?: boolean;
  padded?: boolean;
  className?: string;
  classNameInner?: string;
  component?: React.ElementType;
  cardRef?: React.Ref<T | undefined>;
  innerRef?: React.Ref<HTMLDivElement>;
}

function Card<T>({
  children,
  className,
  classNameInner = undefined,
  component: Component = 'div',
  padded = true,
  innerRef,
  disabled = false,
  cardRef,
  ...rest
}: CardProps<T>) {
  return (
    <Component
      disabled={disabled}
      ref={cardRef}
      className={cx(
        styles.root,
        { [styles.disabled]: disabled, [styles.padded]: padded },
        className,
      )}
      {...rest}
    >
      <div className={cx(styles.inner, classNameInner)} ref={innerRef}>
        {children}
      </div>
    </Component>
  );
}

export default Card;
