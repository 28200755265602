import React, { ReactNode } from 'react';
import { isFeatureActive } from 'utils/FeatureFlag';

import LanguagePicker from '../blocks/LanguagePicker';
import ModalContainer from '../blocks/ModalContainer';
import Feature from '../components/FeatureFlag';
import AppContext from '../containers/AppContext';
import useLoginReducer from '../reducers/login';
import { UserState } from '../reducers/user';
import { Market } from '../utils/constants';
import { ToggleableFeature } from '../utils/FeatureFlag/constants';
import HashNavigationProvider from './HashNavigation';

/**
 *
 * The idea here is to keep the app-wide state in the DataLayer.

 * for example:
 * - The logged in user.
 * - Maybe some kind of cache-handling?
 */

const DataLayer: React.FC<{
  children: ReactNode;
  user: UserState | undefined;
  currentMarket: Market;
  language: string | undefined;
}> = ({ user, currentMarket, language = 'nb-no', children = null }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [languageOpen, setLanguageOpen] = React.useState<boolean>(false);
  const [loginState, loginStateDispatch] = useLoginReducer({
    user,
    loggedIn: user !== undefined,
  });

  const languages = [
    { feature: ToggleableFeature.NORWEGIAN_LANGUAGE, label: 'Norsk', code: 'nb' },
    { feature: ToggleableFeature.SWEDISH_LANGUAGE, label: 'Svenska', code: 'sv' },
    { feature: ToggleableFeature.ENGLISH_LANGUAGE, label: 'English', code: 'en' },
  ].filter(({ feature }) => isFeatureActive(feature, currentMarket));

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        language,
        currentMarket,
        loginState,
        loginStateDispatch,
        languageSelectorOpen: languageOpen,
        setLanguageSelectorOpen: setLanguageOpen,
      }}
    >
      <HashNavigationProvider>
        <Feature id={ToggleableFeature.LANGUAGE_PICKER}>
          <LanguagePicker
            languages={languages}
            open={languageOpen}
            onClose={() => setLanguageOpen(false)}
          />
        </Feature>
        <ModalContainer>{children}</ModalContainer>
      </HashNavigationProvider>
    </AppContext.Provider>
  );
};

export default DataLayer;
