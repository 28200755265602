import React from 'react';
import getConfig from 'next/config';

import Signicat from './providers/Signicat';
import Standard from './providers/Standard';

/**
 *
 * A LoginProvider handles the login on its own, and does not act directly on the
 * state above. Instead it communicates through the LoginContexts methods.
 *
 * On success the LoginProvider returns a full or partially populated user object.
 * This component will take care of taking the proper action based on that.
 *
 * This is done to simplify the logic of the login, streamlining the process
 * to be as much the same as possible whichever authentication is used.
 */
const { publicRuntimeConfig } = getConfig();
const LoginProvider = publicRuntimeConfig.BANKID_AUTH ? Signicat : Standard;

type LoginProps = {
  modal: boolean;
};

const Login: React.FC<LoginProps> = ({ modal }) => {
  return <LoginProvider modal={modal} />;
};

export default Login;
