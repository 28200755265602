import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import OnboardingContext from 'blocks/Onboarding/OnboardingContext';
import Button from 'components/Button';
import Radio from 'components/Form/Radio';
import Typography from 'components/Typography';
import NODES from 'djedi-nodes/generic';

import ArrowForwardSVG from '../../../../icons/arrow_forward.svg';
import { ContentWrapper } from '../ContentWrapper';
import styles from './Terms.module.css';

interface TermsProps {
  modal: boolean;
}

const Terms: React.FC<TermsProps> = ({ modal }) => {
  const { acceptTerms } = React.useContext(OnboardingContext);
  const [open, setOpen] = React.useState(false);

  return (
    <Form
      onSubmit={async ({ terms_ok }) => {
        if (terms_ok) {
          acceptTerms();
        }
      }}
    >
      {({ handleSubmit, submitError }) => (
        <form onSubmit={handleSubmit}>
          <ContentWrapper modal={modal}>
            <Typography variant="heading" align="center">
              {NODES.TERMS_OF_SERVICE}
            </Typography>
            {open ? (
              <Typography variant="md">{NODES.TERMS_OF_SERVICE_LONG}</Typography>
            ) : (
              <>
                <Typography align="center">{NODES.TERMS_OF_SERVICE_DETAILS}</Typography>
                <div className={styles.termsButtonWrapper}>
                  <Typography
                    align="center"
                    variant="link"
                    color="denim"
                    onClick={() => setOpen(true)}
                  >
                    {NODES.TERMS_OF_SERVICE_READ_MORE}
                  </Typography>
                </div>
              </>
            )}
            {submitError && <div className="error">{submitError}</div>}{' '}
            <div className={styles.bottom}>
              <div className={styles.inputWrapper}>
                <Field
                  type="radio"
                  render={Radio}
                  name="terms_ok"
                  value="ok"
                  label={NODES.TERMS_OK}
                />
              </div>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <Button
                    icon={ArrowForwardSVG}
                    onClick={acceptTerms}
                    block
                    disabled={values.terms_ok !== 'ok'}
                  >
                    {NODES.NEXT_STEP}
                  </Button>
                )}
              </FormSpy>
            </div>
          </ContentWrapper>
        </form>
      )}
    </Form>
  );
};

export default Terms;
