import React, { ComponentType, ReactElement } from 'react';
import getConfig from 'next/config';

import { DjediNodeState } from '../../types/DjediNode';
import { UserState } from '../reducers/user';
import { COUNTRY_CODES, FULLSCREEN_LOGIN_MARKETS, hashesType, Market } from './constants';

export function clamp(value: number, min: number, max: number): number {
  return value <= max ? (value >= min ? value : min) : max;
}

export function makeCookies(cookieObject: Record<string, unknown>): string {
  let cookieString = '';
  if (typeof cookieObject !== 'object') {
    return '';
  }
  for (const prop in cookieObject) {
    const value = cookieObject[prop] as string;
    cookieString += ` ${prop}=${value};`;
  }
  return cookieString;
}

// Stolen from django-bananas.js
export function capitalize(str: string) {
  if (!str) return str;
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
}

// Used in HashNavigation
export const hashExists = (hashArray: Array<string>) => {
  const regex = hashArray
    .reduce((acc, hash, i) => {
      return `${acc}${hash}${i < hashArray.length - 1 ? '|' : ''}`;
    }, '(')
    .concat(')');

  return (
    typeof window === 'undefined' || window.location.hash.search(new RegExp(regex, 'g')) !== -1
  );
};

/**
 * Remove all keys whose values are undefined or null from an object.
 */
export function deleteNil(object: Record<string, unknown>) {
  return Object.entries(object).reduce((result: Record<string, unknown>, [key, value]) => {
    if (value != null) {
      result[key] = value;
    }
    return result;
  }, {});
}

/*
 * Used with final form fields to make sure value is empty string and not removed
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const parseEmpty = (value: string | number, name: string): never => value;

export const findHashFromHashes = (hashes: Array<string>) => {
  if (typeof window !== 'undefined' && hashExists(hashes)) {
    const found = hashes.find(hash => hash === window.location.hash);
    if (!found) {
      return undefined;
    }
    return found;
  }

  return undefined;
};

export const getHashesArray = (hashes: hashesType) =>
  Object.keys(hashes).map((key: string) => hashes[key as never]);

export const asPathHashStrippingPattern = /#.+$/;
export const asPathWithoutHash = (asPath: string) => asPath.replace(asPathHashStrippingPattern, '');

// Extract things from djedi nodes

const COMMENT_REGEX = /^\s*\|/;
const LINK_REGEX = /^\s*(.+?)\s*\|\s*([^|\s]+)\s*$/;

export function parseDjediLinks(content: string) {
  return content
    .split('\n')
    .map(line => {
      const match = LINK_REGEX.exec(line);
      return COMMENT_REGEX.test(line)
        ? undefined
        : match != null
        ? { text: match[1], href: match[2] }
        : undefined;
    })
    .filter(Boolean);
}

export function userComplete(user: UserState) {
  return user.first_name && user.last_name && user.email;
}

export function userHasPhone(user: UserState) {
  return user.phone_number ? true : false;
}

export function userHasCompletedSignup(user?: UserState) {
  if (!user) {
    return false;
  }
  return Boolean(user.first_name && user.last_name && user.email && user.phone_number);
}

export const isSSR = () => {
  const { serverRuntimeConfig } = getConfig();
  return serverRuntimeConfig.API_URL !== undefined;
};

type PureFunction<T> = (arg: T) => T;

/**
 *  Turn phone numbers that is formatted with country code like this:
 *  "0046XXXXXX" into "+46XXXXXXX" ( E164 )
 */
export const phoneE164: PureFunction<string> = val =>
  val?.startsWith('00') ? val.replace('00', '') : val;

export const trimBeginningZeros: PureFunction<string> = val =>
  val?.startsWith('0') ? val.replace('0', '') : val;

export const trimCountryCode: PureFunction<string> = val =>
  Object.values(COUNTRY_CODES).reduce((s, cc) => (val.startsWith(cc) ? s.replace(cc, '') : s), val);

export const extractCountryCode: PureFunction<string> = val => {
  return Object.values(COUNTRY_CODES).reduce((v, code) => (val.startsWith(code) ? code : v), '');
};

// export function combinePureFunctions<T>(arg: Array<PureFunction<T>>): T {
//   const solution = arg.reduce((res, f: PureFunction) => f(res));
//   return solution;
// }

export const extractDjediContent = (node: ReactElement) => {
  return React.cloneElement(node, {
    edit: false,
    render: (state: DjediNodeState) => {
      if (!state || state.type !== 'success' || !state.content) {
        return null;
      }
      return state.content;
    },
  });
};

export const shouldFullscreenLogin = (currentMarket: Market) => {
  return FULLSCREEN_LOGIN_MARKETS.includes(currentMarket);
};

/**
 * Stolen from https://glitch.com/edit/#!/imaginary-lycra?path=src%2FHoverable%2FcreateHoverMonitor.js%3A38%3A34
 * Touch devices emulate mouse events. This functions makes it possible to know
 * if the current modality supports hover (including for multi-modality
 * devices).
 */
export const createHoverMonitor = () => {
  let isHoverEnabled = false;
  let lastTouchTime = 0;

  function enableHover() {
    if (isHoverEnabled || Date.now() - lastTouchTime < 500) {
      return;
    }
    isHoverEnabled = true;
  }

  function disableHover() {
    lastTouchTime = Date.now();
    if (isHoverEnabled) {
      isHoverEnabled = false;
    }
  }

  if (typeof document !== 'undefined') {
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
  }

  return {
    get isEnabled() {
      return isHoverEnabled;
    },
  };
};

export const activeSession = (cookie: string) => cookie.includes('sessionid');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const omitSingle = (key, { [key]: _, ...obj }) => obj;

/**
 * Select the correct component for the current market
 *
 * @param market Current market
 * @param components Object with components for each market
 * @returns Component for the current market
 */
export const selectMarketComponent = <P>(
  market: Market,
  components: Required<Pick<Record<Market | 'default', ComponentType<P>>, 'default'>> &
    Partial<Record<Market, ComponentType<P>>>,
): ComponentType<P> => {
  return components[market] ?? components['default'];
};

/**
 * Emulate the spacing system from SCSS
 * Sourced from postcss.config.js
 *
 * @param n Spacing value
 * @returns Spacing value in pixels as string
 */
export const spacing = (n: number) => `${n * 8}px`;

/**
 *
 * @param price Price to format
 * @returns Formatted price
 */
export const formatPrice = (price: number | string) => {
  return price
    .toString()
    .replace('.00', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
