import cookies from 'js-cookie';
import moment, { Moment } from 'moment';
import getConfig from 'next/config';
import Router from 'next/router';
import { deleteNil } from 'utils';
import {
  CSRF_COOKIE_NAME,
  DEFAULT_MARKET,
  HASHES,
  Market,
  SESSION_COOKIE_NAME,
} from 'utils/constants';

import { Analysis } from '../../types/Analysis';
import { Article, ArticleListResponse } from '../../types/Article';
import { Booking, BookingSlot } from '../../types/Booking';
import { Clinic, ClosedSlot, Service, TimeSlot, TimeSlotTemplate } from '../../types/Clinic';
import { HealthInfo } from '../../types/HealthInfo';
import { JournalInfo } from '../../types/JournalInfo';
import { AddendumDetail, FaqEntry, Lesson, LessonDetail } from '../../types/Lesson';
import { Order } from '../../types/Order';
import { Purchase } from '../../types/Purchase';
import { Staff } from '../../types/Staff';
import { City, ClinicTimeslots, TimeslotsQuery } from '../../types/Timeslots';
import { UserProfileForm } from '../pages/profile';
import { UserState } from '../reducers/user';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const isServer = serverRuntimeConfig.API_URL;

const JSON_REGEX = /\bapplication\/json\b/;

const currentMarket = Market[publicRuntimeConfig.MARKET] || DEFAULT_MARKET;

const Api = (options = { headers: {} }) => {
  /** Functions */
  async function getLessonList() {
    return request<Lesson[]>('GET', 'cms/school/');
  }

  async function getLessonDetail(id: string | number): Promise<LessonDetail> {
    return new Promise((resolve, reject) => {
      request<LessonDetail>('GET', `cms/school/${id}/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  async function completeLesson(id: string | number, data?: CompleteLessonData): Promise<void> {
    return new Promise((resolve, reject) => {
      request<void>('POST', `cms/school/${id}/complete/`, data)
        .then(result => {
          if (result.response.status === 204) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // id and slug is interchangeable
  async function getLandingPageDetail(id: string | number): Promise<AddendumDetail> {
    return new Promise((resolve, reject) => {
      return request<AddendumDetail>('GET', `cms/landing_page/${id}/`).then(result => {
        if (result.response.status === 200) {
          resolve(result.data);
        }
        reject(result);
      });
    });
  }

  async function requestLogin(): Promise<SignicatSessionRequest> {
    return new Promise((resolve, reject) => {
      request<SignicatSessionRequest>('POST', 'cms/signicat_session/')
        .then(result => {
          if (result.response.status === 201) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  async function makeSession(sessionId: string, date_consented?: Date): Promise<UserState> {
    return new Promise((resolve, reject) => {
      request<UserState | SignicatTosRequiredResponse>(
        'POST',
        `cms/signicat_session/${sessionId}/success/`,
        {
          date_consented,
        },
      )
        .then(result => {
          if (result.response.status === 400) {
            // Need to consent to ToS
            const tosRequired = result.data as SignicatTosRequiredResponse;
            if (tosRequired.date_consented) {
              reject(tosRequired);
            }
          } else if (result.response.status === 200) {
            resolve(result.data as UserState);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  async function cancelSession(sessionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      request('POST', `cms/signicat_session/${sessionId}/invalidate/`)
        .then(result => {
          if (result.response.status === 204) {
            resolve();
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  async function updateProfile(profileData: UserProfileForm): Promise<UserState> {
    return new Promise((resolve, reject) => {
      request<UserState>('PATCH', `cms/user/me/`, profileData)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  async function login(username: string, password: string): Promise<UserState> {
    return new Promise((resolve, reject) => {
      request<UserState | SignicatTosRequiredResponse>('POST', 'cms/login/', {
        username,
        password,
      })
        .then(result => {
          const { data, response } = result;
          if (response.status === 200) {
            resolve(data as UserState);
          } else if (response.status === 400) {
            const { date_consented } = data as SignicatTosRequiredResponse;
            if (date_consented) {
              reject(date_consented);
            }
          }
          reject(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async function me(): Promise<UserState> {
    return new Promise((resolve, reject) => {
      request<UserState>('GET', 'cms/user/me/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          } else {
            reject(result.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async function logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      request<undefined>('POST', 'cms/logout/', {})
        .then(result => {
          if (result.response.status === 204) {
            cookies.remove(SESSION_COOKIE_NAME);
            cookies.remove(CSRF_COOKIE_NAME);
            resolve();
          } else {
            reject(result.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async function getAnalyses(): Promise<Array<Analysis>> {
    return new Promise((resolve, reject) => {
      request<Array<Analysis>>('GET', 'cms/analysis/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Previews
  async function getLessonPreview(id: string | number): Promise<LessonDetail> {
    return new Promise((resolve, reject) => {
      request<LessonDetail>('GET', `cms/school_preview/${id}/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Previews
  async function getFaq(): Promise<FaqEntry[]> {
    return new Promise((resolve, reject) => {
      request<FaqEntry[]>('GET', `cms/faq/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  type PhoneValidation = { session_token: string };

  // Change phone number
  async function requestPhoneNumberChange(phone_number: string) {
    return request<PhoneValidation>('POST', `cms/user/me/change-phone-number/request/`, {
      phone_number,
    });
  }

  // Change phone number
  async function confirmPhoneNumberChange(security_code: string, session_token: string) {
    return request<UserState>('POST', `cms/user/me/change-phone-number/confirm/`, {
      security_code,
      session_token,
    });
  }

  // Get articles
  async function getArticles(type?: number): Promise<Array<Article>> {
    return new Promise((resolve, reject) => {
      request<ArticleListResponse>('GET', 'pos/catalog/', type !== undefined ? { type } : undefined)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data.articles);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Set health info
  async function setHealthInfo(data: HealthInfo): Promise<boolean> {
    return new Promise((resolve, reject) => {
      request('POST', 'cms/health_info/', data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(false);
          } else if (result.response.status === 201) {
            resolve(true);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get health info
  async function getHealthInfo(): Promise<HealthInfo> {
    return new Promise((resolve, reject) => {
      request<HealthInfo>('GET', 'cms/health_info/')
        .then(result => resolve(result.data))
        .catch(err => reject(err));
    });
  }

  // Get clinics
  async function getClinics(): Promise<Array<Clinic>> {
    return new Promise((resolve, reject) => {
      request<Array<Clinic>>('GET', 'cms/clinic/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get single clinic
  async function getClinic(slug: string | number): Promise<Clinic> {
    return new Promise((resolve, reject) => {
      request<Clinic>('GET', `cms/clinic/${slug}/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic timeslots
  async function getClinicTimeSlots(from: Moment, to: Moment): Promise<Array<TimeSlotTemplate>> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<ListResponse<TimeSlotTemplate>>('GET', `timeslots/slots/`, {
        from_date: from.toISOString(),
        to_date: to.toISOString(),
      })
        .then(result => {
          if (result.response.status === 200) {
            const data = result.data;
            resolve(data.results);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get cities
  async function getCities(): Promise<City[] | undefined> {
    return new Promise((resolve, reject) => {
      request<City[]>('GET', 'cms/clinic/cities/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get services
  async function getServices(): Promise<Array<Service>> {
    return new Promise((resolve, reject) => {
      request<Array<Service>>('GET', 'cms/service/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Search clinic timeslots
  async function searchTimeslots(query: TimeslotsQuery): Promise<ClinicTimeslots[] | undefined> {
    return new Promise((resolve, reject) => {
      request<{ results: ClinicTimeslots[] }>('GET', 'cms/clinic/search_timeslots/', {
        from_date: moment(query.date_from).toDate().toISOString(),
        to_date: (query.date_to ? moment(query.date_to) : moment(query.date_from).add(1, 'months'))
          .toDate()
          .toISOString(),
        city: query.city,
        service: query.service,
      })
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data?.results);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  async function updateClinicTimeSlot(
    id: number,
    data: Record<string, unknown>,
  ): Promise<TimeSlot> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<TimeSlot>('PUT', `timeslots/slots/${id}/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  async function createClinicTimeSlot(data: Record<string, unknown>): Promise<TimeSlot> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<TimeSlot>('POST', `timeslots/slots/`, data)
        .then(result => {
          if (result.response.status === 201) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  async function deleteClinicTimeSlot(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest('DELETE', `timeslots/slots/${id}/`)
        .then(result => {
          if (result.response.status === 204) {
            resolve();
          }
          reject();
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic closed slot
  async function getClinicClosedSlots(): Promise<Array<ClosedSlot>> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<ListResponse<ClosedSlot>>('GET', `timeslots/closed/`)
        .then(result => {
          if (result.response.status === 200) {
            const data = result.data;
            resolve(data.results);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Create clinic closed slot
  async function createClinicClosedSlot(data): Promise<ClosedSlot> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<ClosedSlot>('POST', `timeslots/closed/`, data)
        .then(result => {
          if (result.response.status === 201) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Update clinic closed slot
  async function updateClinicClosedSlot(id: string | number, data): Promise<ClosedSlot> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<ClosedSlot>('PATCH', `timeslots/closed/${id}/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Delete clinic closed slot
  async function deleteClinicClosedSlot(id: string | number): Promise<void> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest('DELETE', `timeslots/closed/${id}/`)
        .then(result => {
          if (result.response.status === 204) {
            resolve();
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic timeslots
  async function getClinicClosedWeekdays(): Promise<Array<number>> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<{
        weekdays: Array<number>;
      }>('GET', `timeslots/closed/weekdays/`)
        .then(result => {
          if (result.response.status === 200) {
            const { weekdays } = result.data;
            resolve(weekdays);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Set clinic closed weekdays
  async function setClinicClosedWeekdays(data): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<ListResponse<string>>('POST', `timeslots/closed/weekdays/`, data)
        .then(result => {
          if (result.response.status === 200) {
            const data = result.data;
            resolve(data.results);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic bookings
  async function getClinicBookings(
    status?: string,
    from?: Date,
    to?: Date,
    patient_id?: number | string,
  ): Promise<Array<Booking>> {
    return new Promise((resolve, reject) => {
      const payload = {};
      if (status) {
        payload['status'] = status;
      }
      if (from) {
        payload['from_date'] = from.toISOString();
      }
      if (to) {
        payload['to_date'] = to.toISOString();
      }
      if (patient_id) {
        payload['patient_id'] = patient_id;
      }

      loginCheckingRequest<ListResponse<Booking>>('GET', `booking/bookings/`, payload)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data.results);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic booking
  async function getClinicBooking(id: number | string): Promise<Booking> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Booking>('GET', `booking/bookings/${id}/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Confirm booking
  async function acceptClinicBooking(id: number | string): Promise<Booking> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Booking>('POST', `booking/bookings/${id}/accept/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Reject booking
  async function rejectClinicBooking(id: number | string, notify = true): Promise<Booking> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Booking>('POST', `booking/bookings/${id}/reject/`, { notify })
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Confirm booking
  async function editClinicBooking(id: number | string, data): Promise<Booking> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Booking>('PATCH', `booking/bookings/${id}/edit/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Place order
  async function placeOrder(data: Order): Promise<boolean> {
    return new Promise((resolve, reject) => {
      request('POST', 'pos/order/order/', data)
        .then(result => {
          if (result.response.status === 201) {
            resolve(true);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Set journal info
  async function setJournalInfo(data: JournalInfo): Promise<boolean> {
    return new Promise((resolve, reject) => {
      request('POST', 'cms/journal/', data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(false);
          } else if (result.response.status === 201) {
            resolve(true);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get journal info
  async function getJournalInfo(): Promise<JournalInfo> {
    return new Promise((resolve, reject) => {
      request<JournalInfo>('GET', 'cms/journal/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get order
  async function getOrder(): Promise<Purchase> {
    return new Promise((resolve, reject) => {
      request<Purchase>('GET', 'pos/order/')
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Update order
  async function updateOrder(id, data): Promise<Purchase> {
    return new Promise((resolve, reject) => {
      request<Purchase>('PUT', `pos/order/${id}/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic
  async function getUserClinic(id): Promise<Clinic> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Clinic>('GET', `clinic/clinic/${id}/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Update clinic
  async function updateClinic(id, data): Promise<Clinic> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Clinic>('PATCH', `clinic/clinic/${id}/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // List staff
  async function getStaff(): Promise<Array<Staff>> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Array<Staff>>('GET', `clinic/staff/`)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Update staff
  async function updateStaff(id, data): Promise<Staff> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Staff>('PATCH', `clinic/staff/${id}/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Create staff
  async function createStaff(data): Promise<Staff> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Staff>('POST', `clinic/staff/`, data)
        .then(result => {
          if (result.response.status === 201) {
            resolve(result.data);
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Delete staff
  async function deleteStaff(id): Promise<void> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest('DELETE', `clinic/staff/${id}/`)
        .then(result => {
          if (result.response.status === 204) {
            resolve();
          }
          reject(result);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic bookable slots
  async function getBookableSlots(
    clinic_id: number | string,
    from: Date,
    to: Date,
    service?: string,
  ): Promise<Array<BookingSlot>> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<ListResponse<BookingSlot>>('GET', `cms/clinic/${clinic_id}/timeslots/`, {
        from_date: from.toISOString(),
        to_date: to.toISOString(),
        service,
      })
        .then(result => {
          if (result.response.status === 200) {
            const data = result.data;
            resolve(data.results);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  // Get clinic bookable slots
  async function bookTime(clinic_id: number | string, data): Promise<Booking> {
    return new Promise((resolve, reject) => {
      loginCheckingRequest<Booking>('POST', `cms/clinic/${clinic_id}/timeslots/`, data)
        .then(result => {
          if (result.response.status === 201) {
            resolve(result.data);
          }
          if (result.response.status === 409) {
            reject(false);
          }
          if (result.response.status === 400) {
            reject(result.data[0]);
          }
          reject(result.data);
        })
        .catch(err => reject(err));
    });
  }

  /** Base */
  async function request<T>(
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
    apiUrl: string,
    data?: Record<string, unknown>,
  ): Promise<{ response: Response; data: T }> {
    const isGET = method === 'GET';

    const urlBase = `${
      isServer ? serverRuntimeConfig.API_URL : publicRuntimeConfig.API_URL
    }/api/v1.0/${apiUrl}`;

    // Just to be sure, always append a slash to the urls.
    if (!urlBase.endsWith('/')) {
      throw new Error('URLS needs to end with a slash');
    }

    const url =
      isGET && data
        ? `${urlBase}?${Object.keys(data)
            .map(key => key + '=' + data[key])
            .join('&')}`
        : urlBase;

    let extraHeaders = {};

    if (!isServer) {
      extraHeaders = deleteNil({
        'X-CSRFToken': typeof document !== 'undefined' ? cookies.get(CSRF_COOKIE_NAME) : undefined,
        Cookie: document.cookie,
        'Accept-Language': Router.locale || 'nb',
      });
    } else {
      if (serverRuntimeConfig.BASIC_AUTH_USER && serverRuntimeConfig.BASIC_AUTH_PASSWORD) {
        const auth = Buffer.from(
          `${serverRuntimeConfig.BASIC_AUTH_USER}:${serverRuntimeConfig.BASIC_AUTH_PASSWORD}`,
        ).toString('base64');
        extraHeaders = {
          Authorization: `Basic ${auth}`,
        };
      }
    }

    const headers = {
      'Content-Type': 'application/json',
      Market: currentMarket,
      ...extraHeaders,
      ...options.headers,
    };

    const response: Response = await fetch(url, {
      method,
      //mode: 'no-cors',
      credentials: 'include',
      headers,
      body: data != null && !isGET ? JSON.stringify(data) : undefined,
    });
    const isJSON = JSON_REGEX.test(response.headers.get('Content-Type') || '');

    const returnData: T = await (isJSON ? response.json() : response.text());
    return { response, data: returnData };
  }

  async function loginCheckingRequest<T>(
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
    apiUrl: string,
    data?: Record<string, unknown>,
  ): Promise<{ response: Response; data: T }> {
    return new Promise((resolve, reject) => {
      request<T>(method, apiUrl, data)
        .then(({ response, data }) => {
          if (response.status === 403) {
            // If we're clientside, hard-refresh and redirect to login
            if (typeof window !== 'undefined') {
              window.location.href = '/' + HASHES.LOGIN;
            }
            reject(response);
          }
          resolve({ response, data });
        })
        .catch(reject);
    });
  }

  async function surveyLogin(data: SurveyLoginData): Promise<{ survey_url: string }> {
    return new Promise((resolve, reject) => {
      request<{ survey_url: string }>('POST', `cms/survey/register/`, data)
        .then(result => {
          if (result.response.status === 200) {
            resolve(result.data);
          } else {
            reject(result.data);
          }
        })
        .catch(err => reject(err));
    });
  }

  return {
    getLessonDetail,
    getLessonList,
    getFaq,
    completeLesson,
    getLandingPageDetail,
    requestLogin,
    makeSession,
    cancelSession,
    updateProfile,
    login,
    logout,
    me,
    getAnalyses,
    getLessonPreview,
    requestPhoneNumberChange,
    confirmPhoneNumberChange,
    getArticles,
    getClinics,
    getClinic,
    setHealthInfo,
    getHealthInfo,
    placeOrder,
    setJournalInfo,
    getJournalInfo,
    getOrder,
    updateOrder,
    getUserClinic,
    updateClinic,
    getStaff,
    updateStaff,
    deleteStaff,
    createStaff,
    getClinicTimeSlots,
    getCities,
    getServices,
    searchTimeslots,
    updateClinicTimeSlot,
    createClinicTimeSlot,
    deleteClinicTimeSlot,
    getClinicClosedSlots,
    createClinicClosedSlot,
    updateClinicClosedSlot,
    deleteClinicClosedSlot,
    getClinicClosedWeekdays,
    setClinicClosedWeekdays,
    getClinicBookings,
    getClinicBooking,
    acceptClinicBooking,
    rejectClinicBooking,
    editClinicBooking,
    getBookableSlots,
    bookTime,
    surveyLogin,
  };
};

export default Api;
