export type Route = { href: string; query?: Record<string, string> };

const ROUTES = {
  HOME: { href: '/' },
  DASHBOARD: { href: '/dashboard' },
  SCHOOL: { href: '/school' },
  LESSON: { href: '/school/lesson' },
  ABOUT_ME: { href: '/about-me' },
  CHOOSE_ANALYSIS: { href: '/booking', query: { step: 'choose-analysis' } },
  MY_ORDER: { href: '/my-order' },
  NIPT_SCHEMA: { href: '/nipt-schema' },
  RESULTS: { href: '/results' },
  PROFILE: { href: '/profile' },
  TIMESLOTS: {
    href: '/nipt-book/forste-ledige-time',
    queryKeys: {
      CITY: 'c',
      DATE_FROM: 'd',
      /** See `searchTimeslotsVariant`. Array can be specified. */
      VARIANT: 'v',
      SERVICE: 'service',
    },
    CLINIC: {
      queryKeys: {
        SLUG: 'slug',
      },

      BOOK: {
        href: '/book',
        queryKeys: {
          START_AT: 's',
          END_AT: 'e',
        },
      },
    },
  },
  CLINICS: { href: '/nipt-book/klinikk' },
  CLINIC_ADMIN: { href: '/admin/clinics' },
  CLINIC_ADMIN_TIMES: { href: '/admin/clinics/times' },
  CLINIC_ADMIN_BOOKINGS: { href: '/admin/clinics/bookings' },
  CLINIC_ADMIN_PATIENTS: { href: '/admin/clinics/patients' },
};

export default ROUTES;
