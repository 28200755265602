import React from 'react';
import getConfig from 'next/config';
import { Market } from 'utils/constants';
const { publicRuntimeConfig } = getConfig();

const MARKET_SPECIFIC_STYLES: Partial<Record<Market, string>> = {
  SE: `:root{ --baby-pink: #DFF3EA; }`,
};

const MarketStyle = () => {
  const s = MARKET_SPECIFIC_STYLES[publicRuntimeConfig.MARKET];
  if (s) {
    return <style>{s}</style>;
  }
  return null;
};

export default MarketStyle;
