import React from 'react';
import { UserState } from 'reducers/user';

export type OnBoardingState = {
  session_id: undefined | string;
  session_token: undefined | string;
  terms: boolean;
  phone_number: undefined | string;
  user: undefined | UserState;
  next_url: undefined | string;
};

export type OnboardingContextType = {
  state: OnBoardingState;
  handleLogin: (user: UserState) => void;
  abandon: () => void;
  acceptTerms: () => void;
  verifyFailed: () => void;
  addMissingUserData: (user: UserState) => void;
  setPhoneDetails: (phone_number: string, session_token: string) => void;
  verifyPhoneNumber: (user: UserState) => void;
  newUser: (session: string) => void;
};

const OnboardingContext = React.createContext<OnboardingContextType>({
  state: {
    user: undefined,
    session_id: undefined, // signicat
    terms: false,
    phone_number: undefined,
    session_token: undefined, // phone verification
    next_url: undefined,
  },
  verifyPhoneNumber: () => {
    /** not empty */
  },
  newUser: () => {
    /** not empty */
  },
  setPhoneDetails: () => {
    /** not empty */
  },
  verifyFailed: () => {
    /** not empty */
  },
  handleLogin: () => {
    /** not empty */
  },
  abandon: () => {
    /** not empty */
  },
  acceptTerms: () => {
    /** not empty */
  },
  addMissingUserData: () => {
    /** not empty */
  },
});

export default OnboardingContext;

export const useOnboardingContext = () => React.useContext(OnboardingContext);
