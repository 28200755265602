import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';

import Button from '../../components/Button';
import Radio from '../../components/Form/Radio';
import Typography from '../../components/Typography';
import CrossIcon from '../../icons/close.svg';
import styles from './LanguagePicker.module.css';

type LanguageChoice = {
  code: string;
  label: string | React.ReactNode;
};

interface LanguagePickerProps extends React.HTMLProps<HTMLDivElement> {
  languages: Array<LanguageChoice>;
  onClose: () => void;
  open: boolean;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  className,
  languages,
  onClose,
  open = false,
}) => {
  const router = useRouter();
  return (
    <div
      className={cx(className, styles.root, {
        [styles.open]: open,
      })}
    >
      <Button className={styles.close} onClick={onClose} icon={CrossIcon} variant="singleicon" />
      <div className={styles.languages}>
        <Typography variant="strong">Other languages</Typography>
        {languages.map((language, key) => (
          <Radio
            meta={{}}
            input={{
              name: 'language',
              value: language.code,
              onChange: () => undefined,
              onBlur: () => undefined,
              onFocus: () => undefined,
              checked: router.locale == language.code,
            }}
            key={key}
            onClick={() => {
              router.push(router.route, router.asPath, {
                locale: language.code,
              });
            }}
            label={<>{language.label}</>}
          />
        ))}
      </div>
    </div>
  );
};

export default LanguagePicker;
