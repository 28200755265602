import { useReducer } from 'react';

import { UserState } from './user';

export const LoginActions = {
  SET_USER: 'SET_USER',
};

export function login(user: UserState) {
  return {
    type: LoginActions.SET_USER,
    user,
  };
}

export function logout() {
  return {
    type: LoginActions.SET_USER,
    user: undefined,
  };
}

export function set(user: UserState) {
  return {
    type: LoginActions.SET_USER,
    user,
  };
}

export type LoginState = {
  loggedIn: boolean;
  user?: UserState;
};

export const initialState = {
  loggedIn: false,
};

export type LoginAction = ReturnType<typeof login | typeof logout | typeof set>;

function reducer(state: LoginState, action: LoginAction): LoginState {
  switch (action.type) {
    case LoginActions.SET_USER:
      const setAction = action as ReturnType<typeof login>;

      return {
        ...state,
        user: setAction.user,
        loggedIn: setAction.user !== undefined,
      };
    default:
      return state;
  }
}

export default function useLoginReducer(state: LoginState = initialState) {
  return useReducer(reducer, state);
}
