import React from 'react';
import classnames from 'classnames';

import styles from './container.module.css';

type Props = {
  fullHeight?: boolean;
  className?: string;
  desktop?: boolean;
  noCollapse?: boolean;
  component?: React.ElementType;
};

/**
 * Contained content, adding a max-width and vertical padding.
 * Should be used in conjunction with <Block/> to create layoues.
 *
 * @param component Underlying component of choice. defaults to div
 * @param fullHeight 100% height
 * @param className className
 * @param desktop allows width to be up to var(--content-max-width-desktop)
 * @param noCollapse prevents the container from collapsing on size down
 * @param children children
 * @returns A functional component
 */

const Container: React.FC<Props> = ({
  component = 'div',
  fullHeight = false,
  desktop = false,
  noCollapse = false,
  className,
  children,
  ...rest
}) => {
  const Component = component;
  return (
    <Component
      className={classnames(
        styles.root,
        {
          [styles.fullHeight]: fullHeight,
          [styles.desktop]: desktop,
          [styles.noCollapse]: noCollapse,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Container;
