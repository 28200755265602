import React from 'react';
import { Field, Form } from 'react-final-form';
import Block from 'components/Block';
import Button from 'components/Button';
import FieldSet from 'components/Form/FieldSet';
import Input from 'components/Form/Input';
import { normalizeFormErrorData } from 'components/Form/utils';
import Typography from 'components/Typography';
import { ApiContext } from 'contexts/ApiContext';
import NODES from 'djedi-nodes/generic';

import { useOnboardingContext } from '../../../OnboardingContext';
import { ContentWrapper } from '../../ContentWrapper';
import styles from '../Login.module.css';

type LoginFormEvent = {
  username: string;
  password: string;
};

type StandardLoginProps = {
  modal: boolean;
};

const StandardLogin: React.FC<StandardLoginProps> = ({ modal }) => {
  const { handleLogin, abandon } = useOnboardingContext();
  const api = React.useContext(ApiContext);

  const onSubmit = React.useCallback(
    (credentials: LoginFormEvent) => {
      return api
        .login(credentials.username, credentials.password)
        .then(handleLogin)
        .catch(err => {
          if (err.response && err.response.status === 400) {
            return normalizeFormErrorData(err.data);
          }
        });
    },
    [api, handleLogin],
  );

  return (
    <ContentWrapper modal={modal} onModalClose={abandon}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitErrors, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="hero" block>
              {NODES.LOGIN_WELCOME}
            </Typography>
            <Typography variant="text">{NODES.LOGIN_INSTRUCTIONS}</Typography>
            <FieldSet>
              <Field name="username" render={Input} />
              <Field name="password" type="password" render={Input} />

              {!submitting && submitErrors && submitErrors['FINAL_FORM/form-error'].length > 0 && (
                <Typography type="smallheading" className={styles.error}>
                  {submitErrors['FINAL_FORM/form-error']}
                </Typography>
              )}
            </FieldSet>
            <Block>
              <Button variant="outlined" type="submit" block>
                {NODES.LOGIN}
              </Button>
            </Block>
          </form>
        )}
      </Form>
    </ContentWrapper>
  );
};

export default StandardLogin;
