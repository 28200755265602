import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import styles from './Input.module.css';

interface Props extends FieldRenderProps<string, HTMLInputElement | HTMLTextAreaElement> {
  className?: string;
  rootClass?: string;
  block?: boolean;
  label?: string;
  required?: boolean;
}

/**
 * Input for use with FinalForm.
 * if FinalForm is not used, props for the input has to be passed through the "input" prop.
 *
 * Example usage:
 * <Field name={""} render={Input}/>
 *
 *
 * @param className "className"
 * @param rootClass class applied to root element
 * @param label label
 * @param block render as a block-level element
 */

const Input: React.FC<Props> = ({
  className,
  rootClass,
  multiline = false,
  block = true,
  input,
  label,
  meta,
  ...props
}) => {
  const hasError = meta.submitError || (meta.touched && meta.error);
  const Component = multiline ? 'textarea' : 'input';
  return (
    <label className={cx(styles.inputRootNode, { [styles.block]: block }, rootClass)}>
      {label && <span className={styles.label}>{label}</span>}
      <Component
        className={cx(
          styles.root,
          {
            [styles.block]: block,
            [styles.error]: hasError,
            [styles.empty]: !input.value,
            [styles.multiline]: multiline,
          },
          className,
        )}
        {...input}
        {...props}
      />
      {hasError && (
        <div className={styles.errorMsg}>
          {meta.submitError} {meta.error}
        </div>
      )}
    </label>
  );
};

export default Input;
