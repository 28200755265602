import React from 'react';
import { Node } from 'djedi-react';

const pageName = 'profile';

const NODES = {
  META_TITLE: <Node uri={`${pageName}/meta/title`}>Profil</Node>,
  NAV_TITLE: <Node uri={`${pageName}/nav/title`}>Profil</Node>,
  HEADER_TITLE: <Node uri={`${pageName}/header-title`}>Profil</Node>,
  INTRO: <Node uri={`${pageName}/intro`}>Endre profilinnstillinger</Node>,
  DASHBOARD: <Node uri={`${pageName}/to-dashboard`}>Dashboard</Node>,
  CHANGE: <Node uri={`${pageName}/change`}>Endre</Node>,
  CHANGE_NUMBER: <Node uri={`${pageName}/change-number`}>Endre telefonnummer</Node>,
  CHANGE_PROFILE: <Node uri={`${pageName}/change-profile`}>Endre informasjon</Node>,
  CODE_OK: <Node uri={`${pageName}/change-profile`}>Bekreftet!</Node>,
  BAD_CODE: <Node uri={`${pageName}/change-profile`}>Fel kod</Node>,
  VERIFY_PHONE: <Node uri={`${pageName}/verify-number`}>Verifiera ditt telefonnummer</Node>,
  CODE_SENT: (
    <Node uri={`${pageName}/verify-number`} number="-1">
      Vi har skickat en verifikations-kod till [number]
    </Node>
  ),
  VERIFY: <Node uri={`${pageName}/please-verify`}>Verifiera</Node>,
  BACK: <Node uri={`${pageName}/back`}>Tillbaka</Node>,
  SUCCESSFULLY_CHANGED_NUMBER: (
    <Node uri={`${pageName}/number-change-success`}>Ditt telefonnummer är uppdaterat</Node>
  ),
};

export const FORM = {
  NAME: <Node uri={`${pageName}/form/name`}>Navn</Node>,
  SURNAME: <Node uri={`${pageName}/form/surname`}>Efternavn</Node>,
  PHONE: <Node uri={`${pageName}/form/phone`}>Telefonnummer</Node>,
  EMAIL: <Node uri={`${pageName}/form/email`}>E-postadresse</Node>,
  SAVE: <Node uri={`${pageName}/form/save`}>Lagre</Node>,
  SUCCESS: <Node uri={`${pageName}/form/success`}>Informasjonen din er lagret</Node>,
  NO_CODE: <Node uri={`${pageName}/form/no-code`}>Ikke fått noen sms?</Node>,
  VERIFICATION_INSTRUCTIONS: (
    <Node uri={`${pageName}/verify-phone-info.md`}>
      For å oppdatere telefonnummeret ditt må du ha telefonen tilgjengelig for å motta en
      bekreftelseskode.
    </Node>
  ),
  PHONE_MODALTITLE: <Node uri={`${pageName}/phone-modal-title`}>Endre ditt telefonnummer</Node>,
  SEND_VERIFICATION: <Node uri={`${pageName}/send-verification`}>Send bekreftelseskode</Node>,
};

export const ERRORS = {
  REQUIRED: <Node uri={`${pageName}/errors/required`}>Obligatoriske felt</Node>,
  PHONE_TOO_SHORT: (
    <Node uri={`${pageName}/errors/phone-to-short`}>Telefonnummeret er for kort</Node>
  ),
  NEEDS_COUNTRY_CODE: (
    <Node uri={`${pageName}/errors/needs-countrycode`}>Telefonnummeret har ingen landskode</Node>
  ),
};

export default NODES;
