/*
Turn this:
    {"detail": "User already exists"}
And this:
    {"__all__": "User already exists"}
Also this:
    {"non_field_errors": ["User already exists"]}
Into this, with `FORM_ERROR` from final-form:
    {[FORM_ERROR]: ["Passwords must match"], "<field_name>": ["This field is required"]}
*/
import { FORM_ERROR } from 'final-form';

import { radioBoolean } from '../../utils/enums';

export type ResponseError = {
  non_field_errors: Array<never>;
  __all__: Array<never>;
  detail: never;
};

export function normalizeFormErrorData(data: ResponseError): Record<never, string> {
  if (data != null && typeof data === 'object') {
    const { non_field_errors = [], __all__ = [], detail, ...errors } = data;
    return {
      [FORM_ERROR]: non_field_errors.concat(__all__).concat(detail).filter(Boolean),
      ...errors,
    };
  } else {
    return data;
  }
}

export const requiredValidator = (value: never): string | undefined =>
  value !== undefined && value !== '' && value !== null ? undefined : 'Required';

export const ssnValidator = (value: never): string | undefined => {
  const emptyError = value !== undefined && value !== '' && value !== null ? undefined : 'Required';

  if (emptyError) {
    return emptyError;
  }

  const ssnRegex = new RegExp('^-*([0-9]-*){10,}$');

  if (!ssnRegex.test(value)) {
    return 'Ugyldig personnummer';
  }

  return undefined;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const parseNumber: never = (value, name?) =>
  value != undefined && value != '' ? parseInt(value, 10) : undefined;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const parseRadioBoolean: never = (value, name?) =>
  (value != undefined && value != '' && (value === 'true' ? radioBoolean.YES : radioBoolean.NO)) ||
  '';
