import React, { Dispatch } from 'react';
import * as Sentry from '@sentry/nextjs';
import Block from 'components/Block';
import Container from 'components/Container';
import AppContext from 'containers/AppContext';
import { ApiContext } from 'contexts/ApiContext';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { login, logout } from 'reducers/login';
import { UserState } from 'reducers/user';
import { userHasCompletedSignup } from 'utils';

import ROUTES from '../../../routes';
import { isFeatureActive } from '../../utils/FeatureFlag';
import { ToggleableFeature } from '../../utils/FeatureFlag/constants';
import OnboardingContext, { OnBoardingState } from './OnboardingContext';
import AddPhone from './partials/AddPhone';
import FinalizeUser from './partials/FinalizeUser';
import Login from './partials/Login';
import Terms from './partials/Terms';
import { VerifyPhone } from './partials/VerifyPhone';

type PassFunction = (ctx: OnBoardingState) => string;

/**
 * This is some kind of half-implemented state machine ...
 * pass : Processes the state and returns an action based on it.
 * Component: The component state should render
 * on: List of actionable reroutes that can take place depending on what pass returns
 */

type State = {
  Component: React.FC<{ modal: boolean }>;
  on: { [char: string]: string };
  pass: PassFunction;
};

type Machine = {
  states: { [char: string]: State };
  initial: string;
};

/**
 * O N B O A R D I N G
 * =======================================================
 *  1. Login / Signup
 *  -> Has account
 *    -> Is admin and has ?next=xxx
 *       -> Go to admin page
 *    -> Go to step 7.
 *  -> New user, continue onboarding:
 *  2. Accept the Terms
 *  -> Deny
 *    -> Abandon the login
    -> Accept
        -> Create the user. Move on:
 *  3. Add a phone number
        -> Create a verification code
 *  4. Verify the numnber over sms
        -> Wrong code
          -> Choices:
              -> Try again ?
              -> Go back to step 3.
        -> Correct code
          -> Move on:
 *  5. Complete the missing user data
    6. Show a welcome screen
    7. redirect user to /dashboard
 */

/* Functions with criteria for passing certain stages in the machine */

const pass_logged_out: PassFunction = ctx => {
  return ctx.user !== undefined ? 'JUMP' : ctx.session_id ? 'SUCCESS' : 'STAY';
};

const pass_admin_redirect: PassFunction = ctx => {
  return ctx.user?.is_superuser === true && ctx.next_url !== undefined ? 'SUCCESS' : 'JUMP';
};

const pass_terms: PassFunction = ctx => (ctx.user !== undefined ? 'SUCCESS' : 'STAY');

const pass_add_phone: PassFunction = ctx =>
  ctx.user?.phone_number ? 'JUMP' : ctx.phone_number && ctx.session_token ? 'SUCCESS' : 'STAY';

const pass_verify_phone: PassFunction = ctx =>
  ctx.user?.phone_number && ctx.session_token
    ? 'SUCCESS'
    : !ctx.phone_number || !ctx.session_token
    ? 'FAIL'
    : 'STAY';

const pass_finalize_user: PassFunction = ctx =>
  userHasCompletedSignup(ctx.user as UserState) ? 'SUCCESS' : 'STAY';

/** The (kind of) "state machine" */

const machine: Machine = {
  initial: 'logged_out',
  states: {
    logged_out: {
      pass: pass_logged_out,
      Component: Login,
      on: { SUCCESS: 'terms', JUMP: 'check_admin' },
    },
    check_admin: {
      pass: pass_admin_redirect,
      Component: () => null,
      on: { SUCCESS: 'admin_redirect', JUMP: 'add_phone' },
    },
    terms: {
      pass: pass_terms,
      Component: Terms,
      on: { SUCCESS: 'add_phone', FAILURE: 'logged_out' },
    },
    add_phone: {
      pass: pass_add_phone,
      Component: AddPhone,
      on: { SUCCESS: 'verify_phone', JUMP: 'finalize_user' },
    },
    verify_phone: {
      pass: pass_verify_phone,
      Component: VerifyPhone,
      on: { SUCCESS: 'finalize_user', FAIL: 'add_phone' },
    },
    finalize_user: {
      pass: pass_finalize_user,
      Component: FinalizeUser,
      on: { SUCCESS: 'redirect' },
    },
    redirect: {
      pass: () => '',
      Component: () => null,
      on: { SUCCESS: 'finish' },
    },
  },
};

function machineReducer(state: string, action: string) {
  if (action === 'STAY') {
    return state;
  }
  const currentState = machine.states[state];
  const nextState = currentState.on[action];
  return nextState ? nextState : state;
}

const useMachine = () => {
  const [state, act] = React.useReducer(machineReducer, machine.initial);
  return [machine.states[state], act] as [State, Dispatch<string>];
};

type OnboardingProps = {
  modal?: boolean;
};

const Onboarding: React.FC<OnboardingProps> = ({ modal = true }) => {
  // hooks
  const api = React.useContext(ApiContext);
  const router = useRouter();
  const { loginStateDispatch, loginState, currentMarket } = React.useContext(AppContext);
  const [machineState, action] = useMachine();
  const { publicRuntimeConfig } = getConfig();
  const next_endpoint = Array.isArray(router.query.next) ? router.query.next[0] : router.query.next;
  const next_url =
    next_endpoint !== undefined
      ? publicRuntimeConfig.API_URL + (next_endpoint || '/_/admin/')
      : undefined;

  // The internal state for the whole onboarding
  const [state, setState] = React.useState<OnBoardingState>({
    session_id: undefined, // signicat
    session_token: undefined, // phone verification
    terms: false,
    phone_number: undefined,
    next_url: next_url,
    user: loginState.loggedIn ? loginState.user : undefined,
  });

  // Evaluate the new state and see if it should pass or render
  React.useEffect(() => {
    if (machineState !== undefined) {
      const pass = machineState.pass(state);
      action(pass);
    }
  }, [state, action, machineState]);

  // Abandon the login/signup process
  const abandon = React.useCallback(async () => {
    try {
      if (loginState.loggedIn) {
        await api.logout();
      }
      loginStateDispatch && loginStateDispatch(logout());
      // route to current path just remove #login part and thereby close modal
      const path = router.asPath;
      router.push(path.slice(0, path.indexOf('#')));
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [loginState.loggedIn, loginStateDispatch, router, api]);

  // Callback for successful login
  const handleLogin = React.useCallback(
    (user: UserState): void => {
      setState({ ...state, user });
      loginStateDispatch && loginStateDispatch(login(user));

      // Temporarily we redirect on login to clinic admin. Later we might want to prompt the user
      if (
        user.clinic_id != undefined &&
        !(user.is_superuser && state.next_url) &&
        isFeatureActive(ToggleableFeature.CLINIC_ADMIN, currentMarket)
      ) {
        window.location.href = ROUTES.CLINIC_ADMIN.href;
      }
    },
    [state, loginStateDispatch, currentMarket],
  );

  // user is not finished. Setting session_id
  const newUser = (session_id: string) => {
    setState({ ...state, session_id });
  };

  // accept the terms or abandon.
  const acceptTerms = () => {
    if (state.session_id) {
      api
        .makeSession(state.session_id, new Date())
        .then(handleLogin)
        .catch(() => {
          action('FAILED');
        });
    } else {
      // You only get here if you're on dev :)
      // ... so let's leave it for now.
    }
  };

  // set the data from the request-phone-number
  const setPhoneDetails = (phone_number: string, session_token: string): void => {
    setState({ ...state, phone_number, session_token });
  };

  // Trigger verification of the phone number ready with returning the new user
  const verifyPhoneNumber = (user: UserState) => {
    setState({ ...state, user });
  };

  const verifyFailed = () => {
    setState({ ...state, phone_number: undefined, session_token: undefined });
  };

  const addMissingUserData = (user: UserState): void => {
    setState({ ...state, user });
  };

  // Finally, update the usercontext with the full user and redirect to /dashboard.
  const finish = React.useCallback(
    (user: UserState): void => {
      loginStateDispatch && loginStateDispatch(login(user));
      router.push(ROUTES.DASHBOARD.href).then(() => window.scrollTo(0, 0));
    },
    [router, loginStateDispatch],
  );
  const redirectAdmin = React.useCallback(
    (user: UserState): void => {
      loginStateDispatch && loginStateDispatch(login(user));
      if (state.next_url) {
        window.location.href = state.next_url;
      }
    },
    [loginStateDispatch, state.next_url],
  );

  React.useEffect(() => {
    if (machineState?.on.SUCCESS === 'finish') {
      finish(state.user as UserState);
    }
    if (machineState?.on.SUCCESS === 'admin_redirect' && machineState.pass(state) === 'SUCCESS') {
      redirectAdmin(state.user as UserState);
    }
  }, [machineState, router, finish, redirectAdmin, state, currentMarket]);
  // extract the current component
  const { Component } = machineState ? machineState : { Component: null };

  // Prefetch the routes where the user will likely end up after logging in
  React.useEffect(() => {
    router.prefetch(ROUTES.DASHBOARD.href);
  }, [router]);

  return (
    <>
      <OnboardingContext.Provider
        value={{
          abandon,
          handleLogin,
          acceptTerms,
          newUser,
          verifyFailed,
          verifyPhoneNumber,
          setPhoneDetails,
          addMissingUserData,
          state,
        }}
      >
        <Container>
          <Block>{Component !== null && <Component modal={modal} />}</Block>
        </Container>
      </OnboardingContext.Provider>
    </>
  );
};

export default Onboarding;
