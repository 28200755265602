import React from 'react';
import cx from 'classnames';

import Container from '../Container';
import styles from './Actions.module.css';

interface ActionsInterface {
  className?: string;
  spaced?: boolean;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  contained?: boolean;
  children?: React.ReactNode;
}

const Actions: React.FC<ActionsInterface> = ({
  className = '',
  spaced = true,
  paddingTop = true,
  paddingBottom = true,
  contained = true,
  children = undefined,
}) => {
  const content = contained ? <Container>{children}</Container> : children;

  return (
    <div
      className={cx(
        styles.root,
        {
          [styles.spaced]: spaced === true,
          [styles.paddingTop]: paddingTop === true,
          [styles.paddingBottom]: paddingBottom === true,
        },
        className,
      )}
    >
      {content}
    </div>
  );
};

export default Actions;
