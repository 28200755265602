import React from 'react';

const useNextFrame = (open: boolean): boolean => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;
    if (typeof window !== 'undefined') {
      if (open === true && ready === false && mounted) {
        window.requestAnimationFrame(() => {
          setReady(true);
        });
      } else if (ready === true && open === false && mounted) {
        window.requestAnimationFrame(() => {
          setReady(false);
        });
      }
    }
    return () => {
      mounted = false;
    };
  }, [ready, open]);
  return ready;
};

export default useNextFrame;
