import React from 'react';
import cx from 'classnames';

import styles from './WithColor.module.css';

export interface WithColorProps {
  background?: Color;
  color?: Color;
  className?: string;
}

type Settings = {
  background?: Color;
  color?: Color;
};

/* 
  takes a color as background and/or color and injects it into the className
  ergo : The wrapped component _needs_ to be able to accept a className,
  
  defaults can be set by passing {color: "white", background: "denim"} into the HOC as a second argument
  
  color props are left as is for reference
*/

export const getColorStyles = (key: Color | undefined, bg = false, responsive = false) => {
  const cl = key ? styles[bg ? `bg-${key}${responsive ? `-responsive` : ''}` : key] : undefined;

  return key && cl ? cl : undefined;
};

function WithColor<P extends Record<string, unknown>>(
  Component: React.ComponentType<P>,
  defaults?: Settings,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const WithColorHOC = React.forwardRef((props: P & WithColorProps, _) => {
    const { color, background, className, ...finalProps } = { ...defaults, ...props };
    const withColorStyles = cx(
      getColorStyles(background, true),
      getColorStyles(color, false),
      className ? className : undefined,
    );

    // The props here might need sanitizing. There's a risk that "background" and "color" leaks into the HTML.
    return <Component {...(finalProps as P)} className={withColorStyles} />;
  });
  WithColorHOC.displayName = `WithColor(${Component.displayName || ''})`;
  return WithColorHOC;
}

export default WithColor;
