import { ReactElement, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'components/Modal';

import styles from './ContentWrapper.module.css';

interface ContentWrapperProps {
  children: ReactNode;
  modal: boolean;
  actions?: ReactElement | ReactElement[];
  onModalClose?: () => void;
}

/**
 * Renders a ContentWrapper component
 *
 * @param children - ReactNode to render
 * @param modal - Determines if a modal should be rendered
 * @param actions - ReactElement to render as actions
 * @param onModalClose - Function to run when the modal is closed
 *
 */

export const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  modal,
  actions,
  onModalClose = () => null,
}) => {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalRoot(document.getElementById('action-root'));
  }, []);

  if (modal === true) {
    return (
      <Modal className={styles.modal} open={true} onClose={onModalClose} actions={actions} noPortal>
        {children}
      </Modal>
    );
  } else {
    if (!portalRoot) return null;
    return (
      <>
        {ReactDOM.createPortal(<div>{actions}</div>, portalRoot)}
        <div className={styles.fullscreen}>{children}</div>
      </>
    );
  }
};
