import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import { extractCountryCode, phoneE164, trimBeginningZeros, trimCountryCode } from '../../../utils';
import { COUNTRY_CODES, COUNTRY_EMOJIS } from '../../../utils/constants';
import ScreenReaderOnly from '../../ScreenReaderOnly';
import styles from './PhoneInput.module.css';

interface Props extends FieldRenderProps<string, HTMLInputElement> {
  label?: string;
  className?: string;
  rootClass?: string;
}

const DEFAULT = COUNTRY_CODES.NORWAY;

/**
 * PhoneInput for use with FinalForm.
 * if FinalForm is not used, props for the PhoneInput has to be passed through the "PhoneInput" prop.
 *
 * Example usage:
 * <Field name={""} render={PhoneInput}/>
 *
 *
 * @param label label
 */

const formatValue = (str: string) => {
  return [phoneE164, trimBeginningZeros, trimCountryCode].reduce((val, f) => f(val), str);
};

const PhoneInput: React.FC<Props> = ({
  input: { onChange, value, ...input },
  label,
  className,
  rootClass,
  meta,
  required,
  placeholder,
}) => {
  const [country, setCountry] = React.useState(
    value ? extractCountryCode(value) || DEFAULT : DEFAULT,
  );
  const [number, setNumber] = React.useState<string | undefined>(
    value ? trimCountryCode(value) : undefined,
  );

  React.useEffect(() => {
    onChange(formatValue(number || '') ? `${country}${formatValue(number || '')}` : undefined);
  }, [country, number, onChange]);

  const renderOption = (country: string, nr: string) => (
    <option key={nr} value={nr}>
      {`${COUNTRY_EMOJIS[country]} ${nr}`}
    </option>
  );

  const hasError = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error || meta.invalid;

  return (
    <div className={cx(styles.wrapper, rootClass)}>
      <label className={styles.label} label-for="add-phone">
        {label}
      </label>
      <div className={styles.innerWrapper}>
        <label className={cx(styles.prefixWrapper, rootClass)}>
          <ScreenReaderOnly>country prefix</ScreenReaderOnly>
          <select
            className={cx(styles.prefix, className, { [styles.error]: hasError })}
            value={country}
            onChange={e => setCountry(e.target.value)}
          >
            {Object.entries(COUNTRY_CODES).map(s => renderOption(...s))}
          </select>
          <span className={styles.chevron} />
        </label>
        <input
          id="add-phone"
          className={cx(styles.root, className, {
            [styles.error]: hasError,
          })}
          placeholder={placeholder}
          value={number}
          pattern="[0-9]*"
          required={required}
          onChange={e => {
            const { value: v } = e.target;
            setNumber(v);
          }}
          {...input}
        />
      </div>
      {(meta.submitError || meta.error) && (
        <div className={styles.errorMsg}>
          {meta.submitError} {meta.error}
        </div>
      )}
    </div>
  );
};

export default PhoneInput;
