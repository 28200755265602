import React from 'react';
import cx from 'classnames';

import WithColor from '../../hocs/WithColor';
import SVGClose from '../../icons/close.svg';
import Button from '../Button';
import Card from '../Card';
import styles from './Notice.module.css';

export interface BlockProps {
  className?: string;
  text?: string | React.ReactNode;
  inline?: boolean;
  open?: boolean;
  onClose?: () => void;
  actionColor?: Color;
}

const Notice: React.FC<BlockProps> = ({
  className,
  text = '',
  inline = false,
  open = true,
  onClose,
  actionColor = 'black',
}) => {
  const notice = (
    <Card
      padded
      className={cx(
        styles.root,
        {
          [styles.floating]: !inline,
          [styles.in]: open,
        },
        className,
      )}
    >
      <div className={styles.content}>
        {text && <div>{text}</div>}
        {onClose && (
          <div className={styles.action}>
            <Button
              className={styles.close}
              variant="text"
              color={actionColor}
              icon={SVGClose}
              onClick={onClose}
            />
          </div>
        )}
      </div>
    </Card>
  );
  return notice;
};

export default WithColor(Notice, { background: 'lightgrey', color: 'black' });
