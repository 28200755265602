export const gender = {
  GIRL: 2,
  BOY: 3,
};

export const radioBoolean = {
  NO: 0,
  YES: 1,
};

export const fetusCount = {
  ONE: 4,
  TWO: 5,
};

export const eggType = {
  OWN: 0,
  DONOR: 1,
};

export const resultType = {
  RADIO_BOOLEAN: 1,
  GENDER: 2,
};

export const articleType = {
  ANALYSIS: 0,
  TESTING_LOCATION: 1,
};

export const testOutcome = {
  NEGATIVE: 0,
  POSITIVE: 1,
};

export const journalState = {
  NEW: 0,
  KIT_SENT: 5,
  DATA_COMPLETE: 10,
  ANALYSIS_SENT: 15,
  RESULTS_SENT: 20,
  INVOICED: 25,
};

export const searchTimeslotsVariant = {
  daytime: 0,
  evening: 1,
  weekend: 2,
};

// helpers

export function getKey<T extends Record<string, number>>(
  enumObject: T,
  value: number,
): keyof T | undefined {
  const match = Object.entries(enumObject).find(([, value2]) => value === value2);
  return match == null ? undefined : (match[0] as keyof T);
}

export function getValue<T, K extends keyof T>(enumObject: T, key: K) {
  return enumObject[key];
}
