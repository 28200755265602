import React from 'react';
import Router, { RouterEvent, useRouter } from 'next/router';
import { asPathWithoutHash } from 'utils';
import { findHashFromHashes, getHashesArray } from 'utils';
import { HASHES } from 'utils/constants';

export const HashNavigationContext = React.createContext<{
  hash: string | undefined;
  clearHash: () => void;
  setActiveHash: (hash: string) => void;
}>({
  hash: undefined,
  clearHash: () => undefined,
  setActiveHash: () => {
    /** not empty */
  },
});

export type RouteHandler = (url: string) => void;

const HashNavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const nextRouter = useRouter();
  const [activeHash, setActiveHash] = React.useState<string | undefined>(
    findHashFromHashes(getHashesArray(HASHES)),
  );

  const clearHash = () => nextRouter.push(asPathWithoutHash(nextRouter.asPath));

  React.useEffect(() => {
    const handleRoutingEvent = (event: RouterEvent) => (url: string) => {
      const hash = /(#.+)?$/.exec(url)?.[0];
      if (hash !== window.location.hash) {
        console.error(
          `HashNavigationProvider: ${event}: Hash mismatch:`,
          hash,
          window.location.hash,
        );
      }

      if (hash) {
        const found = Object.values(HASHES).includes(hash);
        if (!found) {
          setActiveHash(undefined);
        } else {
          setActiveHash(hash);
        }
      } else {
        setActiveHash(undefined);
      }
    };

    const events = [
      // ['routeChangeStart', handleRoutingEvent('routeChangeStart')], // Fires when a route starts to change
      ['routeChangeComplete', handleRoutingEvent('routeChangeComplete')], // Fires when a route changed completely
      // ['beforeHistoryChange', handleRoutingEvent('beforeHistoryChange')], // Fires just before changing the browser's history
      // ['hashChangeStart', handleRoutingEvent('hashChangeStart')], // Fires when the hash will change but not the page
      ['hashChangeComplete', handleRoutingEvent('hashChangeComplete')], // Fires when the hash has changed but not the page
      // [
      //   'routeChangeError',
      //   (err, url) => handleRoutingEvent('routeChangeError')(url, err),
      // ], // Fires when there's an error when changing routes, or a route load is cancelled
      // err.cancelled - Indicates if the navigation was cancelled
    ];

    events.forEach(e => Router.events.on(e[0] as RouterEvent, e[1] as RouteHandler));
    return () => {
      events.forEach(e => Router.events.off(e[0] as RouterEvent, e[1] as RouteHandler));
    };
  }, [activeHash]);
  return (
    <HashNavigationContext.Provider value={{ hash: activeHash, clearHash, setActiveHash }}>
      {children}
    </HashNavigationContext.Provider>
  );
};

export default HashNavigationProvider;

export const useHashNavigation = () => {
  return React.useContext(HashNavigationContext);
};
