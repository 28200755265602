import React from 'react';
import cx from 'classnames';

import SwooshSVG from '../../icons/swoosh.svg';
import Block from '../Block';
import styles from './Swoosh.module.css';

type Props = {
  from?: Color;
  to?: Color;
  mirrorX?: boolean;
  mirrorY?: boolean;
  className?: string;
};

/**
 * Swoosh: A block component that takes up it's own space to create a "swoosh"
 * transition between two Block element backgrounds.
 *
 * @param from color to transit from
 * @param to color to transit to
 * @param mirrorX mirror shape along X-plane (from/to are left intact)
 * @param mirrorY mirror shape along Y-plane (from/to are left intact)
 * @param className can be overridden, is set on the Block element. Not the SVG
 * @param ...rest is passed to the Block component
 */

const Swoosh: React.FC<Props> = ({
  from = 'white',
  to = 'pink',
  mirrorX = false,
  mirrorY = false,
  className,
  ...rest
}) => {
  return (
    <Block
      background={mirrorY ? from : to}
      padding="none"
      className={cx(styles.wrapper, className)}
      {...rest}
    >
      <SwooshSVG
        alt=""
        fill="currentColor"
        preserveAspectRatio="none"
        className={cx(styles.root, styles[mirrorY ? to : from], {
          [styles.mirrorX]: mirrorX,
          [styles.mirrorY]: mirrorY,
        })}
      />
    </Block>
  );
};

export default Swoosh;
