import React, { Dispatch } from 'react';

import { LoginAction, LoginState } from '../reducers/login';
import { Market } from '../utils/constants';

interface AppContextProps {
  loading: boolean;
  language: string;
  currentMarket: Market;
  setLoading: (n: boolean) => void;
  loginState: LoginState;
  loginStateDispatch?: Dispatch<LoginAction>;
  languageSelectorOpen: boolean;
  setLanguageSelectorOpen: (n: boolean) => void;
}

const AppContext = React.createContext<AppContextProps>({
  loading: false,
  language: 'nb',
  currentMarket: Market.NO,
  languageSelectorOpen: false,
  setLoading: () => {
    /** not empty */
  },
  setLanguageSelectorOpen: () => undefined,
  loginState: { user: undefined, loggedIn: false },
});

export default AppContext;

export const useAppContext = () => React.useContext(AppContext);
