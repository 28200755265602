import React from 'react';
import * as Sentry from '@sentry/nextjs';
import Button from 'components/Button';
import Link from 'components/Link';
import Typography from 'components/Typography';
import { useAppContext } from 'containers/AppContext';
import { useApi } from 'contexts/ApiContext';
import NODES from 'djedi-nodes/generic';
import { STEPS } from 'djedi-nodes/home';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { HASHES } from 'utils/constants';

import { useOnboardingContext } from '../../../OnboardingContext';
import { ContentWrapper } from '../../ContentWrapper';
import styles from '../Login.module.css';

const { publicRuntimeConfig } = getConfig();

/**
 * Signicat
 * A wrapper for the iframe / session creation using Signicat
 */

interface SignicatProps {
  modal: boolean;
}

const Signicat: React.FC<SignicatProps> = ({ modal }) => {
  // contexts
  const router = useRouter();
  const api = useApi();
  const { setLoading } = useAppContext();
  const { abandon } = useOnboardingContext();

  // - Clean up after closing
  const closeAndUnload = React.useCallback(() => {
    setLoading(false);
    abandon();
  }, [setLoading, abandon]);

  // - Handle a failed login
  const loginFailed = React.useCallback(
    (err: never) => {
      Sentry.captureException(err);

      closeAndUnload();
      router.push(`/${HASHES.LOGIN_FAILED}`);
    },
    [closeAndUnload, router],
  );

  const initiate = React.useCallback(() => {
    if (publicRuntimeConfig.BANKID_AUTH) {
      setLoading(true);
      api
        .requestLogin()
        .then(data => {
          const sessionRequest = data as SignicatSessionRequest;
          window.location.href = sessionRequest.url;
        })
        .catch(err => {
          loginFailed(err as never);
        });
    }
  }, [api, loginFailed, setLoading]);

  return (
    <ContentWrapper modal={modal} onModalClose={abandon}>
      <Typography className={styles.text} variant="md">
        {NODES.LOGIN_MODAL_TEXT}
      </Typography>
      <Button className={styles.button} onClick={() => initiate()}>
        {STEPS[2].CTA_TEXT}
      </Button>

      <Typography className={styles.marginTop} variant="faded">
        {NODES.TERMS_OF_SERVICE_PRECONDITION}{' '}
        <Link
          className={styles.marginBottom}
          href="/personvern"
          color="denim"
          target="_blank"
          small
          external
          passHref
        >
          {NODES.TERMS_OF_SERVICE_READ_MORE}
        </Link>
      </Typography>
    </ContentWrapper>
  );
};

export default Signicat;
