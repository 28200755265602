import React from 'react';
import { Form } from 'react-final-form';
import OnboardingContext from 'blocks/Onboarding/OnboardingContext';
import Actions from 'components/Actions';
import Button from 'components/Button';
import FieldSet from 'components/Form/FieldSet';
import PhoneInput from 'components/Form/PhoneInput';
import PlaceholderField from 'components/PlaceholderField';
import Typography from 'components/Typography';
import { ApiContext } from 'contexts/ApiContext';
import NODES from 'djedi-nodes/generic';
import { Node } from 'djedi-react';

import { ContentWrapper } from '../ContentWrapper';

/** Duplicated, remove */
export const FORM = {
  PHONE: <Node uri="test/form/phone">Telefonnummer</Node>,
  NO_CODE: <Node uri="test/form/nocode">Jag fick ingen kod</Node>,
};

export const AddPhone = ({ modal }) => {
  const api = React.useContext(ApiContext);
  const { setPhoneDetails } = React.useContext(OnboardingContext);
  const handleRequest = async (phone_number: string) => {
    const { response, data } = await api.requestPhoneNumberChange(phone_number);
    if (response.status === 200) {
      setPhoneDetails(phone_number, data.session_token);
    }
    if (response.status === 400) {
      return data;
    }
  };

  return (
    <Form onSubmit={({ phone_number }) => handleRequest(phone_number)}>
      {({ handleSubmit, values }) => (
        <form id="add-phone" onSubmit={handleSubmit}>
          <ContentWrapper
            actions={
              <Actions contained={modal}>
                <Button block form="add-phone" type="submit" disabled={values.phone_number === ''}>
                  {NODES.NEXT_STEP}
                </Button>
              </Actions>
            }
            modal={modal}
          >
            <div>
              <Typography align="center" variant="heading" component="h1" block>
                {NODES.ADD_PHONE_TITLE}
              </Typography>
              <FieldSet>
                <Typography align="center" variant="md" component="p">
                  {NODES.ADD_PHONE_CONTENT}
                </Typography>

                <PlaceholderField
                  label={FORM.PHONE}
                  required
                  name="phone_number"
                  placeholder={NODES.ADD_PHONE_PLACEHOLDER}
                  type="text"
                  field={PhoneInput}
                />
              </FieldSet>
            </div>
          </ContentWrapper>
        </form>
      )}
    </Form>
  );
};

export default AddPhone;
