import React from 'react';
import { md, Node } from 'djedi-react';

const djediName = 'cookieBar';

export const CONTENT = (
  <Node uri={`${djediName}/content.md`}>{md`
### Samtykke til informasjonskapsler

For at du skal få en best mulig opplevelse på nettsiden vår, bruker vi informasjonskapsler.
[Les mer om personvern og data integritet](/personvern)
  `}</Node>
);

export const ALLOW_ALL = (
  <Node uri={`${djediName}/submit-all`}>Tillat alle informasjonskapsler</Node>
);
export const ALLOW_SELECTION = <Node uri={`${djediName}/submit-selection`}>Tillat urvalg</Node>;

export const MANDATORY_COOKIES = <Node uri={`${djediName}/mandatory-cookies`}>Nødvendige</Node>;
export const VOLUNTARY_COOKIES = (
  <Node uri={`${djediName}/voluntary-cookies`}>Markedsføring og statistikk</Node>
);
