import React from 'react';
import { md, Node } from 'djedi-react';

const pageName = 'generic';

const NODES = {
  // truly generic
  BASE_PAGE_TITLE: <Node uri={`${pageName}/base-page-title`}>Edda labs</Node>,
  REDIRECTING: <Node uri={`${pageName}/redirecting`}>Du vil snart bli sendt videre. ...</Node>,
  CLOSE: <Node uri={`${pageName}/close`}>steng</Node>,
  APPROVE: <Node uri={`${pageName}/approve`}>Godkjenn</Node>,
  DENY: <Node uri={`${pageName}/deny`}>Avstå</Node>,
  WEEK: <Node uri={`${pageName}/week`}>Vecka</Node>,
  UNDERSTOOD: <Node uri={`${pageName}/understood`}>Forstått</Node>,
  TO_LESSON: <Node uri={`${pageName}/go-to-lesson`}>Til leksjonen</Node>,
  // login
  LOGIN_WELCOME: <Node uri={`${pageName}/login/welcome`}>Velkommen til edda labs</Node>,
  LOGIN_INSTRUCTIONS: (
    <Node uri={`${pageName}/login/instructions`}>Log in eller skap en konto under</Node>
  ),
  LOGIN_FAILED: <Node uri={`${pageName}/login/failed`}>Noe gikk galt. Prøv å logge på igjen.</Node>,
  LOGIN_CANCELLED: <Node uri={`${pageName}/login/cancelled`}>Inloggningen avbröts</Node>,
  LOGIN: <Node uri={`${pageName}/login/cta`}>Log in</Node>,
  LOGIN_MODAL_TEXT: (
    <Node uri={`${pageName}/loginmodal/text.md`}>
      {md`
        # Hei!

        Skal du gjøre NIPT, vil du bare lære mer om fosterdiagnostikk, eller vil du se ditt resultat?

        Logg inn under.
        Tjenesten er gratis.
      `}
    </Node>
  ),
  // employee block
  READ_MORE_ABOUT: (
    <Node uri={`${pageName}/read-more-about`} name="-1">
      Om [name]
    </Node>
  ),
  // Terms of service
  TERMS_OF_SERVICE: <Node uri={`${pageName}/tos/title`}>Brukervilkår</Node>,
  TERMS_OF_SERVICE_DETAILS: (
    <Node uri={`${pageName}/tos/details`}>{md`
      For å registrere deg hos oss, må du godta vilkårene våre.
    `}</Node>
  ),
  TERMS_OF_SERVICE_PRECONDITION: (
    <Node uri={`${pageName}/tos/precondition`}>
      Ved å logge in eller registrere deg godtar du våre vilkår.
    </Node>
  ),
  TERMS_OF_SERVICE_READ_MORE: (
    <Node uri={`${pageName}/tos/read-more`}>Slik behandler vi dine personopplysninger</Node>
  ),
  TERMS_OK: (
    <Node uri={`${pageName}/tos/ok`}>
      Jeg er enig i at Edda Labs håndterer min personlige informasjon
    </Node>
  ),

  NEXT_STEP: <Node uri={`${pageName}/tos/next`}>Neste steg</Node>,
  TERMS_OF_SERVICE_LONG: (
    <Node uri={`${pageName}/tos/long.md`}>{md`
      Edda Labs ber om navn og telefonnummer og email når du går igjennom NIPT skolen dette så vi kan kontakte deg. Ved bestilling av NIPT fyller du i et skjema hvor vi noterer personalia og medisinsk informasjon som er relevant gjeldende NIPT screening.
      Eksempler på informasjon som er relevant gjeldende NIPT screening er; har du vært gravid før, finns det genetiske tilstander i familien, hva er din alder, hvilken graviditets uke er du i, med mer.

      Om du skulle ha kontakt med oss i chat eller per telefon så kan også denne type informasjon diskuteres. Eksempelvis om du har et spesifikt spørsmål og våre leger prater med deg for å avklare dette.

      Om du ønsker å gjøre NIPT screening så analyseres prøven hos Life Genomics laboratoriet i Gøteborg. Våre prøver analysers altså i Sverige. Det finnes inget NIPT laboratorium i Norge så alle Norske prøver fra Norske sykehus analyseres også i Sverige. I forbindelse med sending av prøven så fyller du ut et skjema om hvordan du vil at din data skal håndteres av Life Genomics, for eksempel om du vil delta i forskning eller ikke. Det er helt frivillig og påvirker ikke analysen. Lif Genomics håndterer all data om pasienter i henhold til Svensk Helse Lov.

      Betaling av din NIPT prøve gjøres gjennom faktura eller betalingstjenesten Klarna.

      For å forbedre våre nettsider så bruker vi cookies, det vil si at om du for eksempel har besøkt oss før og begynt NIPT skolen eller utfylling så vil vi kunne se dette slik at du kan fortsette der du avsluttet.
      ​
      Spørsmål omkring vårt personvern og data integritet kan sendes til [info@eddalabs.com](mailto:info@eddalabs.com).
    `}</Node>
  ),
  ADD_PHONE_TITLE: <Node uri={`${pageName}/add-phone/title`}>Telefonnummeret ditt</Node>,
  ADD_PHONE_CONTENT: (
    <Node uri={`${pageName}/add-phone/content.md`}>
      For å kunne kontakte deg når resultatet er fullført, trenger vi telefonnummeret ditt.
    </Node>
  ),
  ADD_PHONE_PLACEHOLDER: <Node uri={`${pageName}/add-phone/placeholder`}>Telefonnummeret</Node>,
  CHANGE_USER_HEADING: <Node uri={`${pageName}/user/heading`}>Snart ferdig!</Node>,
  CHANGE_USER_CONTENT: (
    <Node uri={`${pageName}/user/content.md`}>
      Det siste vi trenger fra deg er ditt fulle navn og e-postadresse.
    </Node>
  ),
  // nav
  DASHBOARD: <Node uri={`${pageName}/nav/dashboard`}>Hjem</Node>,
  HOME: <Node uri={`${pageName}/nav/home`}>Startside</Node>,
  SCHOOL: <Node uri={`${pageName}/nav/school`}>NIPT-skolen</Node>,
  PROFILE: <Node uri={`${pageName}/nav/profile`}>Profil</Node>,
  CLINIC_ADMIN: <Node uri={`${pageName}/nav/clinic-admin`}>Admin</Node>,
  LOG_OUT: <Node uri={`${pageName}/nav/log-out`}>Log ut</Node>,
  PHONE: <Node uri={`${pageName}/verify-code/phone`}>Telefonnummer</Node>,
  VERIFY_TITLE: <Node uri={`${pageName}/verify-code/title`}>Bekreft telefonnummeret ditt</Node>,
  NO_CODE: <Node uri={`${pageName}/verify-code/no-code`}>Mottok du ikke en SMS?</Node>,
  VERIFY: <Node uri={`${pageName}/verify-code/verify`}>Bekreft</Node>,
  CODE_OK: <Node uri={`${pageName}/verify-code/ok-code`}>Telefonnummeret ditt er bekreftet</Node>,
  BAD_CODE: <Node uri={`${pageName}/verify-code/bad-code-input`}>Feil bekreftelsekode</Node>,
  ONBOARDING_READY: <Node uri={`${pageName}/onboarding/done`}>Start NIPT-skolen</Node>,
  DESCRIPTION: (
    <Node phone_number="-1" uri="test/code/verification-sent">
      Vi har sendt en kode til [phone_number]. Skriv inn koden nedenfor for å bekrefte at
      telefonnummeret er riktig.
    </Node>
  ),
};

export const NAV = {
  MAIN: (
    <Node uri="nav/main">{md`
Om EddaLabs | /about
    `}</Node>
  ),
  FOOTER: (
    <Node uri="nav/footer">{md`
      Om oss | /
      Kontakt oss | /faq
      Vilkår og betingelser | /om-oss
      Personvern | /personvern
    `}</Node>
  ),
};

export default NODES;
