import React from 'react';
import cx from 'classnames';

import styles from './FieldSet.module.css';

interface Props extends React.HTMLProps<HTMLFieldSetElement> {
  description?: string | React.ReactNode;
}

/**
 * Fieldset is used to bring semantics, accessibility and some basic styling to inputs or sets of inputs.
 *
 * @param className "className"
 * @param disabled disable entire FieldSet
 * @param description Bolded text rendered as a <legend>.
 */

const FieldSet: React.FC<Props> = ({
  className,
  disabled = false,
  description,
  children,
  ...props
}) => {
  return (
    <fieldset
      disabled={disabled}
      aria-disabled={disabled}
      className={cx(styles.root, className)}
      {...props}
    >
      {description && <legend>{description}</legend>}
      {children}
    </fieldset>
  );
};

export default FieldSet;
