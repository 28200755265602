import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import OnboardingContext from 'blocks/Onboarding/OnboardingContext';
import Actions from 'components/Actions';
import Button from 'components/Button';
import CodeInput from 'components/CodeInput';
import FieldSet from 'components/Form/FieldSet';
import Typography from 'components/Typography';
import { ApiContext } from 'contexts/ApiContext';
import NODES from 'djedi-nodes/generic';
import { FormApi } from 'final-form';
import { UserState } from 'reducers/user';
import { PHONE_VALIDATION_LENGTH } from 'utils/constants';

import { ContentWrapper } from '../ContentWrapper';
import styles from './VerifyPhone.module.css';

/** Duplicated, remove */

type VerifyPhoneProps = {
  modal: boolean;
};

export const VerifyPhone: React.FC<VerifyPhoneProps> = ({ modal }) => {
  const api = React.useContext(ApiContext);
  const { state, verifyPhoneNumber, verifyFailed } = React.useContext(OnboardingContext);
  const [[ok, user], setOk] = React.useState<[boolean, UserState | undefined]>([false, undefined]);
  const [formState, setFormState] = React.useState({ security_code: '' });

  const form = React.useRef<FormApi>();
  const lastSubmittedSecurityCode = React.useRef<string | undefined>();

  // Let the user see that the code was OK for a second
  React.useEffect(() => {
    if (ok) {
      window.setTimeout(() => verifyPhoneNumber(user as UserState), 1000);
    }
  }, [ok, user, verifyPhoneNumber]);

  React.useEffect(() => {
    (async () => {
      if (
        formState.security_code.length === 4 &&
        lastSubmittedSecurityCode.current !== formState.security_code &&
        form.current !== undefined
      ) {
        await form.current.submit();
        lastSubmittedSecurityCode.current = formState.security_code;
      }
    })();
  }, [formState.security_code.length, formState.security_code]);

  return (
    <Form
      validateOnBlur={true}
      initialValues={{ security_code: undefined }}
      onSubmit={async ({ security_code }) => {
        if (state.session_token) {
          const { response, data } = await api.confirmPhoneNumberChange(
            security_code,
            state.session_token,
          );
          if (response.status === 200) {
            setOk([true, data]);
          }
          if (response.status === 400) {
            return data;
          }
          return true;
        }
      }}
    >
      {({ handleSubmit, submitting, form: finalForm }) => {
        form.current = finalForm;

        return (
          <form onSubmit={handleSubmit} id="verify-phone">
            <ContentWrapper
              modal={modal}
              onModalClose={verifyFailed}
              actions={
                <Actions contained={modal}>
                  <Button
                    loading={submitting}
                    form="verify-phone"
                    variant="text"
                    block
                    onClick={verifyFailed}
                  >
                    {NODES.NO_CODE}
                  </Button>
                </Actions>
              }
            >
              <FieldSet className={styles.fieldSet}>
                <Typography variant="heading" component="h2" align="center">
                  {NODES.VERIFY_TITLE}
                </Typography>
                <Typography align="center">
                  {React.cloneElement(NODES.DESCRIPTION, { phone_number: state.phone_number })}
                </Typography>

                <Field
                  required
                  name="security_code"
                  type="number"
                  maxLength={PHONE_VALIDATION_LENGTH}
                  render={CodeInput}
                  success={NODES.CODE_OK}
                  error={NODES.BAD_CODE}
                  max={9999}
                  label={NODES.PHONE}
                  parse={val =>
                    val.length > PHONE_VALIDATION_LENGTH
                      ? val.slice(0, PHONE_VALIDATION_LENGTH)
                      : val
                  }
                />
              </FieldSet>
            </ContentWrapper>

            <FormSpy
              subscription={{ values: true }}
              onChange={({ values: { security_code = '' } }) => {
                setFormState({ security_code });
              }}
            />
          </form>
        );
      }}
    </Form>
  );
};
